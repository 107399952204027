import * as MuiMaterial from "@mui/material";
import * as Antd from "antd";
import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import * as EmotionReact from "@emotion/react";
import * as EmotionStyled from "@emotion/styled";
import * as MuiIconsMaterial from "@mui/icons-material";
import * as MantineCore from "@mantine/core";
import * as MantineDates from "@mantine/dates";
import * as MantineHooks from "@mantine/hooks";
import * as TablerIcons from "@tabler/icons";
import * as Dayjs from "dayjs";
import "./profileStylesheet/EditProfile.css"
import * as AntDesignIcons from "@ant-design/icons";
import { Link } from "react-router-dom";
import Logout from './Logout';
import Rectangle from "../../assets/dashboardAssets/rectangle-99.png";
import Rectangle2 from "../../assets/dashboardAssets/rectangle-3135.svg";
import Bleed from "../../assets/dashboardAssets/section-bleed-2.svg";
import Profile from "../../assets/dashboardAssets/icon-bold-profile-circle.svg";
import DashboardOutline from "../../assets/dashboardAssets/ic-outline-space-dashboard.svg";
import SoundIcon from "../../assets/dashboardAssets/icon-outline-sound.svg";



function App() {

  return (
    <Antd.ConfigProvider>
      <MuiMaterial.ThemeProvider theme={theme}>
        <ProfileEdit {...profileEditData} />
      </MuiMaterial.ThemeProvider>
    </Antd.ConfigProvider>
  );
}

export default App;

const theme = MuiMaterial.createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#9c27b0",
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "#ed6c02",
    },
    info: {
      main: "#0288d1",
    },
    success: {
      main: "#2e7d32",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontSize: 14,
  },
  shape: {
    borderRadius: 4,
  },
});
Antd.ConfigProvider.config({
  theme: {
    primaryColor: "#1890ff",
    secondaryColor: "#1890ff",
    successColor: "#52c41a",
    warningColor: "#faad14",
    errorColor: "#f5222d",
  },
});

function ProfileEdit(props) {  
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
    
  useEffect(() => {
    const fetchUserDetails = async() => {
      try {
        const response = await 
          fetch('https://api.celecast.co/users/get_user_profile/', {
            method: 'GET',
            headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        setUserInfo({
          profile_picture_url: data.profile_picture_url, 
         });
      } catch (error) {
        console.log("Error:" , error);
        console.log(localStorage.getItem('token'))
      }
     };
     fetchUserDetails();
  }, []);

  
  const [userInfo, setUserInfo] = useState({profile_picture_url: null})


  const {
    celecast,
    rectangle99,
    creator,
    overview,
    myPodcasts,
    tools,
    profile,
    version100,
    lf,
    sectionTitle2,
    about,
    contactInformation,
    basicInformation,
    frame11Props,
    frame12Props,
    frame13Props,
    input10Props2,
  } = props;

  return (
    <div  className="edit-profile-container-center-horizontal">
      <div className="edit-profile-profile-edit edit-profile-screen">
      <div className="frame-8149 screen">
        <div className="overlap-group1">
          <div className="rectangle-1"></div>
          <div className="version-100 valign-text-middle opensans-normal-purple-heart-16px">
            Version 1.0.0
          </div>
          <Logout open={openModal} close={handleCloseModal}/>
          <Frame162 onClick={handleOpenModal} icOutlineSpaceDashboard="./assets/dashboardAssets/icon-outline-logout-1.svg" dashBoard="Logout" />
          
          <Link to="/dashboard">
          <Frame1 dashBoard="Dash Board" />
          </Link>
          <Link to="/mypodcasts">
          <Frame8136 profile="My Podcasts" />
          </Link>
          <Link to="#">
          <Frame162
            icOutlineSpaceDashboard="./assets/dashboardAssets/carbon-settings.svg"
            dashBoard="Settings"
            className="frame-8134"
          />
          </Link>
          <Link to="/profile">
          <Frame1 dashBoard="Profile" className="frame-8136 active-link" />
          </Link>
          <div className="overview opensans-normal-cornflower-14px">
            OVERVIEW
          </div>
          <div className="tools opensans-normal-cornflower-14px">
            TOOLS
          </div>
          <div className="frame-2"></div>
          <div className="frame-8146">
            <div className="overlap-group">
              <div className="celecast timesnewroman-bold-white-18px">
                Celecast
              </div>
              <img className="rectangle-99" src={Rectangle} alt="Rectangle 99" />
            </div>
            <div className="creator poppins-medium-amber-18px">
              Creator
            </div>
          </div>
        </div>
      </div>
        <div className="edit-profile-top-nav-bar-container">
          <TopNavBarContent sectionLabelProps={input10Props2.sectionLabelProps} />
        </div>
        <div className="edit-profile-overlap-group4">
          <div className="edit-profile-flex-row">
            <div className="edit-profile-flex-col">
              <div className="edit-profile-flex-column-center">
                  {userInfo.profile_picture_url ? (<img className="edit-profile-overlap-group1" src={userInfo.profile_picture_url} alt="profile-img"/>): <Skeleton />}
                <Button17 />
                </div>
              <img className="edit-profile-section-bleed-1" src={Bleed} alt="section bleed 1" />
              <TextInput />
              <Textarea />
              <Button222 />
              <div className="edit-profile-group-4276">
                <div className="edit-profile-section-title-2 edit-profile-opensans-semi-bold-regent-gray-10px">
                  {sectionTitle2}
                </div>
                <img className="edit-profile-section-bleed-2" src={Bleed} alt="section bleed 2" />
              </div>
              <Button3 />
            </div>
            <div className="edit-profile-flex-col-1">
              <div className="edit-profile-flex-row-1">
                <TextInput2 />
                <TextInput3 />
                <Button4 />
              </div>
              <div className="edit-profile-flex-row-2">
                <TextInput4 />
                <Button5 />
              </div>
              <div className="edit-profile-about">
                <img
                  className="edit-profile-iconboldprofile-circle"
                  src={Profile}
                  alt="icon/bold/profile-circle"
                />
                <div className="edit-profile-about-1 edit-profile-opensans-semi-bold-black-14px">
                  {about}
                </div>
              </div>
              <div className="edit-profile-overlap-group2">
                <img className="edit-profile-rectangle-3135" src={Rectangle2} alt="Rectangle 3135" />
              </div>
              <div className="edit-profile-flex-row-3">
                <div className="edit-profile-flex-col-2 edit-profile-opensans-semi-bold-regent-gray-10px">
                  <div className="edit-profile-contact-information">
                    {contactInformation}
                  </div>
                  <TextInput5 />
                  <TextInput6 />
                  <div className="edit-profile-basic-information">
                    {basicInformation}
                  </div>
                </div>
                <div className="edit-profile-text-input-container">
                  <TextInput7 />
                  <TextInput8 />
                </div>
              </div>
            </div>
          </div>
          <DatePicker />
          <NativeSelect />
          <NativeSelect2 />
        </div>
        <Frame8142 />
      </div>
    </div>
  );
}



function TopNavBarContent(props) {
  const { sectionLabelProps } = props;

  return (
    <div className="edit-profile-top-nav-bar-content">
      <div className="edit-profile-profile-header">
        <SectionLabel>{sectionLabelProps.children}</SectionLabel>
      </div>
    </div>
  );
}

function SectionLabel(props) {
  const { children } = props;

  return (
    <div className="edit-profile-section-label">
      <div className="edit-profile-label edit-profile-opensans-semi-bold-scarpa-flow-14px">
        {children}
      </div>
    </div>
  );
}


function Button17() {
  return (
    <div className="edit-profile-edit-button">
      <MuiMaterial.Button
        endIcon=""
        size="large"
        color="inherit"
        disabled={false}
        variant="outlined"
        disableElevation={false}
        startIcon={<MuiIconsMaterial.Edit />}
      >
        EDIT
      </MuiMaterial.Button>
    </div>
  );
}

function TextInput() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        label="Title"
        iconWidth={20}
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        description="Media Title"
        style={{ width: "200px" }}
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function Textarea() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-textarea">
      <MantineCore.Textarea
        error=""
        size="md"
        minRows={2}
        maxRows={5}
        icon={null}
        value={value}
        description=""
        iconWidth={20}
        disabled={false}
        required={false}
        autosize={false}
        variant="default"
        label="Description"
        style={{ width: "200px" }}
        placeholder="Enter media description"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function Button222() {
  return (
    <div className="edit-profile-add-work-button">
      <MuiMaterial.Button
        endIcon=""
        size="medium"
        disabled={false}
        color="secondary"
        variant="outlined"
        disableElevation={false}
        startIcon={<MuiIconsMaterial.Add />}
      >
        ADD WORK
      </MuiMaterial.Button>
    </div>
  );
}

function Button3() {
  return (
    <div className="edit-profile-add-skills-button">
      <MuiMaterial.Button
        endIcon=""
        size="medium"
        disabled={false}
        color="secondary"
        variant="outlined"
        disableElevation={false}
        startIcon={<MuiIconsMaterial.Add />}
      >
        add skills
      </MuiMaterial.Button>
    </div>
  );
}

function TextInput2() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        iconWidth={20}
        disabled={false}
        required={false}
        label="Full Name"
        multiline={false}
        variant="default"
        style={{ width: "200px" }}
        description="Enter full Name"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function TextInput3() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        iconWidth={20}
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        label="Profession"
        style={{ width: "200px" }}
        description="Current Profession"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function Button4() {
  return (
    <div className="edit-profile-save-button">
      <Link to="/profile">
      <MuiMaterial.Button
        endIcon=""
        size="large"
        disabled={false}
        color="secondary"
        variant="contained"
        disableElevation={false}
        startIcon={<MuiIconsMaterial.Save />}
      >
        Save
      </MuiMaterial.Button>
      </Link>
    </div>
  );
}

function TextInput4() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        iconWidth={20}
        label="Location"
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        style={{ width: "200px" }}
        description="Where you work from"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function Button5() {
  return (
    <div className="edit-profile-cancel-button">
      <Link to="/profile">
      <MuiMaterial.Button
        endIcon=""
        size="large"
        disabled={false}
        color="secondary"
        variant="outlined"
        disableElevation={false}
        startIcon={<MuiIconsMaterial.Cancel />}
      >
        cancel
      </MuiMaterial.Button>
      </Link>
    </div>
  );
}

function TextInput5() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        iconWidth={20}
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        label="Phone Number"
        style={{ width: "200px" }}
        description="Enter Phone Number"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function TextInput6() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        iconWidth={20}
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        label="Personal website"
        style={{ width: "200px" }}
        description="Personal Site Address"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function TextInput7() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        label="Email"
        iconWidth={20}
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        style={{ width: "200px" }}
        description="Enter Email Address"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function TextInput8() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-text-input">
      <MantineCore.TextInput
        error=""
        size="sm"
        icon={null}
        value={value}
        iconWidth={20}
        label="Address"
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        style={{ width: "200px" }}
        description="Enter Home Address"
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function DatePicker() {
  const [value, setValue] = React.useState();

  return (
    <div className="edit-profile-date-picker">
      <MantineDates.DatePicker
        error=""
        size="sm"
        value={value}
        description=""
        iconWidth={30}
        disabled={false}
        required={false}
        multiline={false}
        variant="default"
        onChange={setValue}
        label="Enter Birthday"
        style={{ width: "184px" }}
        placeholder="Select your Birthday"
        icon={<TablerIcons.IconCalendar />}
      />
    </div>
  );
}

function NativeSelect() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-native-select gender-select">
      <MantineCore.NativeSelect
        error=""
        size="sm"
        value={value}
        label="Gender"
        description=""
        disabled={false}
        required={false}
        icon={undefined}
        iconWidth={null}
        variant="default"
        placeholder="Gender"
        data={["Male", "Female"]}
        style={{ width: "184px" }}
        onChange={(event) => setValue(event.currentTarget.value)}
      />
    </div>
  );
}

function NativeSelect2() {
  const [value, setValue] = React.useState("");

  return (
    <div className="edit-profile-native-select genre-select">
      <MantineCore.NativeSelect
        error=""
        size="sm"
        value={value}
        label="Genre"
        description=""
        disabled={false}
        required={false}
        icon={undefined}
        iconWidth={null}
        variant="default"
        placeholder="Enter genre"
        style={{ width: "184px" }}
        onChange={(event) => setValue(event.currentTarget.value)}
        data={["Inspirational", "Spiritual", "Secular", "Financial", "Business", "Culture", "Health"]}
      />
    </div>
  );
}
function Avatar() {

  const [userInfo, setUserInfo] = useState({profile_picture_url: null})

  useEffect(() => {
    const fetchUserDetails = async() => {
      try {
        const response = await 
          fetch('https://api.celecast.co/users/get_user_profile/', {
            method: 'GET',
            headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        setUserInfo({
          profile_picture_url: data.profile_picture_url, 
         });
      } catch (error) {
        console.log("Error:" , error);
        console.log(localStorage.getItem('token'))
      }
     };
     fetchUserDetails();
  }, []);

  return (
    <div className="edit-profile-avatar">
      <img className="edit-profile-avatar" alt="userImage" src={userInfo.profile_picture_url} />
    </div>
  );
}

function Frame8142() {
  return (
    <div className="edit-profile-main-frame">
      <Fab />
      <Avatar />
    </div>
  );
}

function Fab() {
  return (
    <div className="edit-profile-fab">
      <MuiMaterial.Fab size="medium" color="inherit" disabled={false} variant="circular" disableRipple={false}>
        <MuiIconsMaterial.Notifications />
      </MuiMaterial.Fab>
    </div>
  );
}



const frame1621Data = {
  icOutlineSpaceDashboard: "./assets/dashboardAssets/ic-outline-space-dashboard.svg",
  dashBoard: "Dash Board",
};

const frame1622Data = {
  icOutlineSpaceDashboard: "./assets/dashboardAssets/carbon-settings.svg",
  dashBoard: "Settings",
  className: "edit-profile-settings",
};

const frame1623Data = {
  icOutlineSpaceDashboard: "./assets/dashboardAssets/icon-outline-logout.svg",
  dashBoard: "Logout",
  className: "edit-profile-logout",
};

const sectionLabelData = {
  children: "Profile",
};

const topNavBarContentData = {
  sectionLabelProps: sectionLabelData,
};

const profileEditData = {
  celecast: "Celecast",
  rectangle99: "./assets/dashboardAssets/rectangle-99.png",
  creator: "Creator",
  overview: "OVERVIEW",
  myPodcasts: "My Podcasts",
  tools: "TOOLS",
  profile: "Profile",
  version100: "Version 1.0.0",
  lf: "LF",
  sectionTitle2: "Genere",
  about: "About",
  contactInformation: "CONTACT INFORMATION",
  basicInformation: "BASIC INFORMATION",
  frame11Props: frame1621Data,
  frame12Props: frame1622Data,
  frame13Props: frame1623Data,
  input10Props2: topNavBarContentData,
};
function Frame162(props) {
  const { icOutlineSpaceDashboard, dashBoard, className, onClick } = props;

  return (
    <div onClick={onClick} className={`frame-8133 ${className || ""}`}>
      <img className="iconoutlinelogout" src={icOutlineSpaceDashboard} alt="icon/outline/logout" />
      <div className="logout opensans-normal-solitude-14px">
        {dashBoard}
      </div>
    </div>
  );
}

function Frame1(props) {
  const { dashBoard, className } = props;

  return (
    <div className={`frame-1 ${className || ""}`}>
      <img className="no-outline" src={DashboardOutline} alt="ic:outline-space-dashboard" />
      <div className="dash-board opensans-normal-solitude-14px">
        {dashBoard}
      </div>
    </div>
  );
}

function Frame8136(props) {
  const { profile } = props;

  return (
    <div className="frame-8131">
      <img className="iconoutlinesound" src={SoundIcon} alt="icon/outline/sound" />
      <div className="my-podcasts opensans-normal-solitude-14px">
        {profile}
      </div>
    </div>
  );
}