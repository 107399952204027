import useAuth from "./useAuth";
import request from "../api/request";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const refresh = async () => {
    const response = await request.get("/refresh", {
      withCredentials: true,
    });
    setAuth((prev) => {
      console.log({ prev });
      return { ...prev, accessToken: response.data.accessToken };
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
