import { useField } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const TextField = ({ type, ...props }) => {
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"}`;
  const [isShowingPassword, setIsShowingPassword] = useState(false);

  return (
    <InputContainer>
      {type === "password" ? (
        <IconInput className={errorClass}>
          <input
            type={isShowingPassword ? "text" : "password"}
            id={props.name}
            {...field}
            {...props}
            onChange={(ev) => {
              field.onChange(ev);
            }}
          />
          {!isShowingPassword ? (
            <VisibilityIcon
              onClick={() => setIsShowingPassword((prev) => !prev)}
            />
          ) : (
            <VisibilityOffIcon
              onClick={() => setIsShowingPassword((prev) => !prev)}
            />
          )}
        </IconInput>
      ) : (
        <FormInput
          type={type || "text"}
          id={props.name}
          className={errorClass}
          {...field}
          {...props}
          onChange={(ev) => {
            field.onChange(ev);
          }}
        />
      )}
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

const FormInput = styled.input`
  width: 322px;
  height: 43px;
  flex-shrink: 0;
  background: transparent;
  padding-left: 16px;
  border-radius: 6px;
  outline: none;
  border: 1px solid #adbbdb;

  &::placeholder {
    color: #adbbdb;
    font-size: 14px;
    font-weight: 400;
  }

  &.error {
    border: 1px solid red;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #adbbdb inset;
    box-shadow: 0 0 0px 1000px #adbbdb inset;
  }

  &:autofill {
    background-color: transparent;
  }
`;

const IconInput = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;

  svg {
    position: absolute;
    right: 10px;
    width: 0.7em;
    height: 0.7em;
    cursor: pointer;
  }

  input {
    width: 322px;
    height: 43px;
    flex-shrink: 0;
    background: transparent;
    padding-left: 16px;
    padding-right: 40px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #adbbdb;

    &::placeholder {
      color: #adbbdb;
      font-size: 14px;
      font-weight: 400;
    }

    &.error {
      border: 1px solid red;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
    box-shadow: 0 0 0px 1000px #f3f3f3 inset;
  }
`;
