import Img1 from "../../assets/landingPageAssets/userpic.png";
 const userReviews = [
    {   key: 1,
        userName: 'Esther James',
        userTitle: 'Product Designer',
        userImage: {
            alt: "userImage",
            src: {Img1}
        },
        userRemark: "Lorem ipsum dolor sit amet consectetur. Ullamcorper ullamcorper eu ut in consequat amet sapien commodo potenti. Viverra porta sit ultrices ante neque eget eu. Nibh urna tincidunt diam tempor vel ut urna. Volutpat."
    },
    {   key: 2,
        userName: 'Esther James',
        userTitle: 'Product Designer',
        userImage: {
            alt: "userImage",
            src: {Img1}
        },
        userRemark: "Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium. Amet risus eu varius rhoncus. Tincidunt nulla augue amet tortor massa amet. Amet massa consequat libero egestas pulvinar massa justo eget nulla.Ornare rhoncus molestie vel etiam imperdiet. Nibh auctor sagittis."
    },
    {   key: 3,
        userName: 'Esther James',
        userTitle: 'Product Designer',
        userImage: {
            alt: "userImage",
            src: {Img1}
        },
        userRemark: "Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium. Amet risus eu varius rhoncus. Tincidunt nulla augue amet tortor massa amet. Amet massa consequat libero egestas pulvinar massa justo eget "
    },
    {   key: 4,
        userName: 'Esther James',
        userTitle: 'Product Designer',
        userImage: {
            alt: "userImage",
            src: {Img1}
        },
        userRemark: "Lorem ipsum dolor sit amet consectetur. In vulputate aliquam velit a pharetra vestibulum mi. Rhoncus ornare pretium mauris vulputate porttitor sodales. Volutpat erat auctor odio id elit iaculis massa donec imperdiet. Ornare adipiscing enim lacus sed massa dictum tortor."
    },
    {   key: 5,
        userName: 'Esther James',
        userTitle: 'Product Designer',
        userImage: {
            alt: "userImage",
            src: {Img1}
        },
        userRemark: "Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium. Amet risus eu varius rhoncus. Tincidunt nulla ."
    },
    {   key: 6,
        userName: 'Esther James',
        userTitle: 'Product Designer',
        userImage: {
            alt: "userImage",
            src: {Img1}
        },
        userRemark: "Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium. Amet risus eu varius rhoncus. Tincidunt nulla augue amet tortor massa amet. Amet massa consequat libero egestas pulvinar massa justo eget "
    },
  ]

export default userReviews