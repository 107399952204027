import React from "react";
import Home from "./components/landingPage/Home";
import PageNotFound from "./components/static/PageNotFound";
import DashboardViewApp from "./components/dashboard/DashboardViewApp";
import "./globalStyles/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/auth/login";
import Register from "./views/auth/register";
import RequireAuth from "./utilities/requireAuth";
import PersistLogin from "./utilities/persistLogin";
import ForgotPassword from "./views/auth/forgotPassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        <Route element={<PersistLogin />}>
          {/* Add protected routes below in similar format */}
          <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<DashboardViewApp />} />
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
