import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const LoginFooter = () => {
  return (
    <Container>
      <LinkText to={"/signup"}>SignUp Instead</LinkText>
      <TextWrapper>
        <FooterText>Forgot your password?</FooterText>
        <LinkText to={"/forgotpassword"}>Reset Password</LinkText>
      </TextWrapper>
    </Container>
  );
};

export default LoginFooter;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LinkText = styled(Link)`
  color: #177ae5;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FooterText = styled.p`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
