import React, { useEffect } from "react";
import * as Recharts from "recharts";
import "./AreaChart.css"

function App() {
  return <AreaChart />;
}

export default App;

function AreaChart() {
  return (
    <Recharts.AreaChart
      width={600}
      height={200}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      data={[
        {
          month: "Jan",
          "Activity Index": 50000
          
        },
        {
          month: "Feb",
          "Activity Index": 10000
         
        },
        {
          month: "Mar",
          "Activity Index": 250000
          
        },
        {
          month: "Apr",
          "Activity Index": 30000
          
        },
        {
          month: "May",
          "Activity Index": 200000
          
        },
        {
          month: "Jun",
          "Activity Index": 20000
          
        },
        {
          month: "Jul",
          "Activity Index": 50000
         
        },
        {
          month: "Aug",
          "Activity Index": 100000
          
        },
        {
          month: "Sept",
          "Activity Index": 70000
          
        },
        {
          month: "Oct",
          "Activity Index": 80000
          
        },
        {
          month: "Nov",
          "Activity Index": 50000
          
        },
        {
          month: "Dec",
          "Activity Index": 100000
          
        },
      ]}
    >
      <Recharts.CartesianGrid strokeDasharray="3 3" />
      <Recharts.XAxis dataKey="month" />
      <Recharts.YAxis />
      <Recharts.Tooltip offset={10} />
      <Recharts.Legend align="center" verticalAlign="top" />
      <Recharts.Area dataKey="Activity Index" fill="#3366FF" type="monotone" stroke="#3366FF" fillOpacity={0.5} />
    </Recharts.AreaChart>
  );
}