import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { WindowsFilled } from '@ant-design/icons';

export default function ResponsiveDialog(props) {
  const nav = useNavigate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const refresh = () => window.location.reload(true)
  const LogoutUser = () => {

    logoutToken()
    localStorage.removeItem("authenticated");
    localStorage.removeItem("token")
    nav("/")
    refresh()
  }
  const logoutToken = () => {
    fetch('https://api.celecast.co/users/logout', {
      method: 'POST',
      headers:{
      'Content-Type': 'application/json'
    },
    })
    .then(response => {
      if (response.ok) {
        console.log('Logged Out User');
        return response.json();
      } else {
        throw new Error('Unsuccessful Logout');
      }
      
    })
    .catch(error => {
      console.log(error);
    });
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.close}   
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"LOGOUT?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout? <br/>You would need to sign-in next time you visit this page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={LogoutUser}>
            I know, sign me out
          </Button>
          <Button onClick={props.close}>
            I don't want to signout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}