import React, { useState } from "react";
import AuthLayout from "../layout";
import {
  Container,
  FormButton,
  FormWrapper,
  SubTitle,
  Title,
  TitleWrapper,
} from "../login";
import { Form, Formik } from "formik";
import { ForgotPasswordValidation } from "../formValidation";
import { CircularProgress } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { TextField } from "../../../components/formikTextInput";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      // make api call here
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <Container>
        <TitleWrapper>
          <Title>Forgot password?</Title>
          <SubTitle>No worries, we will send you reset instructions</SubTitle>
        </TitleWrapper>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={ForgotPasswordValidation}
          enableReinitialize
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          <Form>
            <FormWrapper>
              <TextField
                name="email"
                placeholder="Enter your e-mail"
                type="email"
              />
              <FormButton type={"submit"} disabled={loading}>
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  `Reset password`
                )}
              </FormButton>
              <BackWrapper to={"/login"}>
                <KeyboardBackspaceIcon size={24} />
                <BackText>Back to log in</BackText>
              </BackWrapper>
            </FormWrapper>
          </Form>
        </Formik>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPassword;

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
  cursor: pointer;
`;

const BackText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;
