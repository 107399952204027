import React from "react";
import { Navigate, useLocation } from "react-router";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ redirectUrl = "/login" }) => {
  const location = useLocation();
  const { auth } = useAuth();

  if (!auth?.token) {
    return <Navigate to={redirectUrl} state={{ from: location }} replace />;
  } else {
    return <Outlet />;
  }
};

export default RequireAuth;
