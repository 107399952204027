import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const AuthLayout = ({ children, footerComponent: FooterComponent }) => {
  return (
    <Container>
      <Header>
        <Logo to={"/"}>Celecast</Logo>
      </Header>
      <AuthCardWrapper>
        <AuthCard>{children}</AuthCard>
        <Ellipse />
        {FooterComponent && <FooterComponent />}
      </AuthCardWrapper>
    </Container>
  );
};

export default AuthLayout;

const Container = styled.div`
  background: #121826;
  width: 100vw;
  height: 110vh;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 70px;
  padding: 0 70px;
`;

const Logo = styled(Link)`
  font-weight: 700;
  font-size: 24px;
  color: #fff;
`;

const AuthCardWrapper = styled.div`
  position: relative;
  isolation: isolate;
  width: fit-content;
  width: 522px;
  margin: 20px auto 0;
`;

const Ellipse = styled.span`
  position: absolute;
  top: 0;
  left: 20px;
  width: 445px;
  height: 400px;
  display: inline-flex;
  border-radius: 454px;
  background: radial-gradient(
    122.62% 85.6% at 78.84% 59.57%,
    #570340 -10%,
    #99a135 100%
  );
  filter: blur(260px);
  z-index: 0;
`;

const AuthCard = styled.div`
  position: relative;
  border-radius: 8px;
  background: #222838;
  color: #fff;
  padding: 67px 40px;
  width: 100%;
  min-height: 431px;
  margin-bottom: 22px;
  z-index: 1;
`;
