import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const RegisterFooter = () => {
  return (
    <Container>
      <TextWrapper>
        <FooterText>Already have an account?</FooterText>
        <LinkText to={"/login"}>Login</LinkText>
      </TextWrapper>
    </Container>
  );
};

export default RegisterFooter;

const Container = styled.div`
@font-face {
  font-family: 'Open Sans';
  src: url('/public/fonts/OpenSans-Regular.ttf') format('truetype');
}
  display: flex;
  justify-content: center;
`;

const LinkText = styled(Link)`
  color: #177ae5;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FooterText = styled.p`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;
