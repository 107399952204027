import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Logout from './Logout'
import AreaChart from './charts/AreaChart'
import './dashboardStylesheets/DashboardMain.css'
import Rectangle from "../../assets/dashboardAssets/rectangle-99.png";
import HeartFill from "../../assets/dashboardAssets/remix-icons-fill-user-user-heart-fill.svg";
import IconOutline from "../../assets/dashboardAssets/icon-outline-export.svg";
import BoldIcon from "../../assets/dashboardAssets/icon-bold-import.svg";
import Background from "../../assets/dashboardAssets/Background.svg";
import IconExport from "../../assets/dashboardAssets/iconboldexport.svg";
import GrommetIcon from "../../assets/dashboardAssets/grommet-icons-form-view.svg";
import DashboardOutline from "../../assets/dashboardAssets/ic-outline-space-dashboard.svg";
import SoundIcon from "../../assets/dashboardAssets/icon-outline-sound.svg";


const DashboardMain = () => {
  const [openModal, setOpenModal] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async() => {
      try {
        const response = await 
          fetch('https://api.celecast.co/users/get_user_profile/', {
            Method: 'GET',
            Headers: {
              Authorization: `Token ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        setUserInfo({
          id: data.id,
          username : data.username, 
          first_name :data.first_name, 
          last_name :data.last_name, 
          email :data.email, 
          dob :data.dob,
          country:data.country, 
          gender: data.gender, 
          phone_number: data.phone_number, 
          profile_picture_url: data.profile_picture_url, 
          email_is_verified: data.email_is_verified, 
          suspended: data.suspended});
      } catch (error) {
        console.log("Error:" , error)
      }
     }
      
  }, []);
   
  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <div className="dashboard-main-container">
    <Logout open={openModal} close={handleCloseModal}/>  
            <div className="frame-8149 screen">
        <div className="overlap-group1">
          <div className="rectangle-1"></div>
          <div className="version-100 valign-text-middle opensans-normal-purple-heart-16px">
            Version 1.0.0
          </div>
          <Link to="#">
           <Frame162 onClick={handleOpenModal} icOutlineSpaceDashboard="./assets/dashboardAssets/icon-outline-logout-1.svg" dashBoard="Logout" /> 
          {/* <div onClick={handleOpenModal}  className={`frame-8133`}>
          <img className="iconoutlinelogout" src="./assets/dashboardAssets/icon-outline-logout-1.svg" alt="icon/outline/logout" />
          <div className="logout opensans-normal-solitude-14px">
        Logout
          </div>
          </div> */}
          </Link>
          <Link to="/dashboard">
          <Frame1 dashBoard="Dash Board" className="active-link" />
          </Link>
          <Link to="/mypodcasts">
          <Frame8136  profile="My Podcasts" />
          </Link>
          <Link to="#">
          <Frame162
            icOutlineSpaceDashboard="./assets/dashboardAssets/carbon-settings.svg"
            dashBoard="Settings"
            className="frame-8134"
          />
          </Link>
          <Link to="/profile">
          <Frame1 dashBoard="Profile" className="frame-8136" />
          </Link>
          <div className="overview opensans-normal-cornflower-14px">
            OVERVIEW
          </div>
          <div className="tools opensans-normal-cornflower-14px">
            TOOLS
          </div>
          <div className="frame-2"></div>
          <div className="frame-8146">
            <div className="overlap-group">
              <div className="celecast timesnewroman-bold-white-18px">
                Celecast
              </div>
              <img className="rectangle-99" src={Rectangle} alt="Rectangle 99" />
            </div>
            <div className="creator poppins-medium-amber-18px">
              Creator
            </div>
          </div>
        </div>
      </div>
        <div className="dashboard-main-content">
        <div className="dashboard-main-main-content">
            <div className="dashboard-main-dashboard-div-container">
                <div className="dashboard-main-dashboard-div">
                  <h1 className="dashboard-main-dashboard-text">Dashboard</h1>
                </div>
            </div>
            <div className="dashboard-main-dashboard-period-stats-container">
                <div className="dashboard-main-period-stats-div">
                    <div className="dashboard-main-gen-stats-frame">
                      <div className="dashboard-main-gen-stats-content-frame">
                        <img
                          className="dashboard-main-stats-img-top"
                          src={HeartFill}
                          alt="remix-icons/fill/user/user-heart-fill"
                        />
                        <div className="dashboard-main-stats-info-div">
                          <div  className="dashboard-main-stats-header dashboard-main-opensans-normal-gray-14px">Total Subscribers</div>
                          <div className="dashboard-main-stats-figure-and-duration-div">
                            <div className="dashboard-main-stats-figure dashboard-main-opensans-semi-bold-scarpa-flow-22px">500k</div>
                            <div className="dashboard-main-stats-duration-img-div">
                              <div className="dashboard-main-stats-duration dashboard-main-opensans-normal-gray-14px">Last 30 days</div>
                              <img className="dashboard-main-stats-duration-img" src={IconOutline} alt="icon/outline/export" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-main-gen-stats-frame">
                      <div className="dashboard-main-gen-stats-content-frame">
                        <img className="dashboard-main-stats-img-top" src={BoldIcon} alt="icon/bold/import" />
                        <div className="dashboard-main-stats-info-div">
                          <div  className="dashboard-main-stats-header dashboard-main-opensans-normal-gray-14px">Total Downloads</div>
                          <div className="dashboard-main-stats-figure-and-duration-div">
                            <div className="dashboard-main-stats-figure dashboard-main-opensans-semi-bold-scarpa-flow-22px">200k</div>
                            <div className="dashboard-main-stats-duration-img-div">
                              <div className="dashboard-main-stats-duration dashboard-main-opensans-normal-gray-14px">Last 30 days</div>
                              <img className="dashboard-main-stats-duration-img" src={IconOutline} alt="icon/outline/export" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-main-gen-stats-frame">
                      <div className="dashboard-main-gen-stats-content-frame">
                        <img className="dashboard-main-stats-img-top" src={IconExport} alt="icon/bold/export" />
                        <div className="dashboard-main-stats-info-div">
                          <div className="dashboard-main-stats-header dashboard-main-opensans-normal-gray-14px">Total Uploads</div>
                          <div className="dashboard-main-stats-figure-and-duration-div">
                            <div className="dashboard-main-stats-figure dashboard-main-opensans-semi-bold-scarpa-flow-22px">700k</div>
                            <div className="dashboard-main-stats-duration-img-div">
                              <div className="dashboard-main-stats-duration dashboard-main-opensans-normal-gray-14px">Last 30 days</div>
                              <img className="dashboard-main-stats-duration-img" src={IconOutline} alt="icon/outline/export" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dashboard-main-gen-stats-frame">
                      <div className="dashboard-main-gen-stats-content-frame">
                        <img className="dashboard-main-stats-img-top" src={GrommetIcon} alt="grommet-icons:form-view" />
                        <div className="dashboard-main-stats-info-div">
                          <div  className="dashboard-main-stats-header dashboard-main-opensans-normal-gray-14px">Total Views</div>
                          <div className="dashboard-main-stats-figure-and-duration-div">
                            <div className="dashboard-main-stats-figure dashboard-main-opensans-semi-bold-scarpa-flow-22px">7500k</div>
                            <div className="dashboard-main-stats-duration-img-div">
                              <div className="dashboard-main-stats-duration dashboard-main-opensans-normal-gray-14px">Last 30 days</div>
                              <img className="dashboard-main-stats-duration-img" src={IconOutline} alt="icon/outline/export" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <div className="dashboard-main-activity-index-analytics-container">
                <p className="dashboard-main-section-header align-text-center">Activity Index Analytics</p>
                <div className="dashboard-main-chart-container" >
                    <AreaChart />
                  </div>
            </div>
            <div className="dashboard-main-all-podcasts">
                <p className="dashboard-main-section-header align-text-center">All Podcasts</p>
                    <table>
                        <thead>
                            <tr className="dashboard-main-table-header">
                                <th>TITLE</th>
                                <th>DOWNLOADS</th>
                                <th>RELEASE DATE</th>
                                <th>STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img"  />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-green">
                                        <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-12px">Published</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-green">
                                        <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-12px">Published</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-green">
                                        <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-12px">Published</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-green">
                                        <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-12px">Published</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                            <tr className="dashboard-main-table-row">
                                <td className="dashboard-main-podcast-title-td">
                                    <div className="dashboard-main-podcast-info-div">
                                        <img src={Background} alt="background" className="dashboard-main-podcast-img" />
                                        <div className="dashboard-main-podcast-title-and-name-div">
                                        <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                                        <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="dashboard-main-downloads-td">
                                    <div className="dashboard-main-download-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">100K</div>
                                </td>
                                <td className="dashboard-main-release-date-td">
                                    <div className="dashboard-main-realease-date-text align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                                </td>
                                <td className="dashboard-main-status-td">
                                    <div className="dashboard-main-badge-pill-yellow">
                                        <div className="dashboard-main-yellow align-text-center">Drafts</div>
                                      </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

            </div>
        </div>
        <div className="dashboard-main-sub-content">
            <div className="dashboard-main-podcaster-of-the-month-container">
                <div className="dashboard-main-podcaster-of-the-month-frame">
                    <div className="dashboard-main-podcaster-of-the-month-content-div">
                        <div className="dashboard-main-podcaster-of-the-month-group-1">
                          <div className="dashboard-main-podcaster-of-the-month-text align-text-center">Podcaster of the Month</div>
                          <div className="dashboard-main-podcaster-of-the-month-name align-text-center">Nse-Abasi Joseph</div>
                          <div className="dashboard-main-avatars">
                            <div className="dashboard-main-avatars-container">
                              <div className="dashboard-main-avatars-web-3">
                                <img className="dashboard-main-background" src={Background} alt="background"  />
                              </div>
                              <div className="dashboard-main-avatars-web-4">
                                <img className="dashboard-main-background" src="./assets/dashboardAssets/background-3.svg" alt="Background" />
                              </div>
                              <div className="dashboard-main-avatars-web-5">
                                <img className="dashboard-main-background" src="./assets/dashboardAssets/background-4.svg" alt="Background" />
                              </div>
                              <div className="dashboard-main-avatars-web-6">
                                <img className="dashboard-main-background" src="./assets/dashboardAssets/background-5.svg" alt="Background" />
                              </div>
                              <div className="dashboard-main-avatars-image24-default">
                                <div className="dashboard-main-overlap-group-2">
                                  <div className="dashboard-main-cu align-text-center">+150</div>
                                </div>
                              </div>
                            </div>
                          </div>
                              <button className="dashboard-main-button-view-profile align-text-center dashboard-main-opensans-semi-bold-scarpa-flow-14px">
                                View profile
                              </button>
                        </div>

                        <div className="dashboard-main-rectangle-3137"></div>
                    </div>
                    <img className="dashboard-main-rectangle-3136" src="./assets/dashboardAssets/Rectangle-3137-framed.svg" alt="Rectangle 3136" />

                  </div>

            </div>
            <div className="dashboard-main-trending">
                <p className="dashboard-main-section-header">Trending</p>
                <div className="dashboard-main-card-container">
                    <div className="dashboard-main-card-frame">
                        <div className="dashboard-main-card-podcast-info">
                          <img className="dashboard-main-avatars-web-7" src={Background} alt="background"/>
                          <div className="dashboard-main-podcast-creator-and-podcast-name">
                            <div className="dashboard-main-podcast-creator align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nathaniel Bassey</div>
                            <div className="dashboard-main-podcast-name align-text-center dashboard-main-opensans-normal-pale-sky-12px">Hallelujah</div>
                          </div>
                        </div>
                        <div className="dashboard-main-podcast-stats">
                          <div className="dashboard-main-podcast-genre align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Gospel Hit</div>
                          <div className="dashboard-main-podcast-view-count align-text-center dashboard-main-opensans-normal-eerie-black-12px">24k Views</div>
                        </div>
                      </div>
                      <div className="dashboard-main-card-frame">
                        <div className="dashboard-main-card-podcast-info">
                          <img className="dashboard-main-avatars-web-7" src={Background} alt="background"/>
                          <div className="dashboard-main-podcast-creator-and-podcast-name">
                            <div className="dashboard-main-podcast-creator align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nathaniel Bassey</div>
                            <div className="dashboard-main-podcast-name align-text-center dashboard-main-opensans-normal-pale-sky-12px">Hallelujah</div>
                          </div>
                        </div>
                        <div className="dashboard-main-podcast-stats">
                          <div className="dashboard-main-podcast-genre align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Gospel Hit</div>
                          <div className="dashboard-main-podcast-view-count align-text-center dashboard-main-opensans-normal-eerie-black-12px">24k Views</div>
                        </div>
                      </div>
                      <div className="dashboard-main-card-frame">
                        <div className="dashboard-main-card-podcast-info">
                          <img className="dashboard-main-avatars-web-7" src={Background} alt="background"/>
                          <div className="dashboard-main-podcast-creator-and-podcast-name">
                            <div className="dashboard-main-podcast-creator align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nathaniel Bassey</div>
                            <div className="dashboard-main-podcast-name align-text-center dashboard-main-opensans-normal-pale-sky-12px">Hallelujah</div>
                          </div>
                        </div>
                        <div className="dashboard-main-podcast-stats">
                          <div className="dashboard-main-podcast-genre align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Gospel Hit</div>
                          <div className="dashboard-main-podcast-view-count align-text-center dashboard-main-opensans-normal-eerie-black-12px">24k Views</div>
                        </div>
                      </div>
                      <div className="dashboard-main-card-frame">
                        <div className="dashboard-main-card-podcast-info">
                          <img className="dashboard-main-avatars-web-7" src={Background} alt="background"/>
                          <div className="dashboard-main-podcast-creator-and-podcast-name">
                            <div className="dashboard-main-podcast-creator align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nathaniel Bassey</div>
                            <div className="dashboard-main-podcast-name align-text-center dashboard-main-opensans-normal-pale-sky-12px">Hallelujah</div>
                          </div>
                        </div>
                        <div className="dashboard-main-podcast-stats">
                          <div className="dashboard-main-podcast-genre align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Gospel Hit</div>
                          <div className="dashboard-main-podcast-view-count align-text-center dashboard-main-opensans-normal-eerie-black-12px">24k Views</div>
                        </div>
                      </div>
                      <div className="dashboard-main-card-frame">
                        <div className="dashboard-main-card-podcast-info">
                          <img className="dashboard-main-avatars-web-7" src={Background} alt="background"/>
                          <div className="dashboard-main-podcast-creator-and-podcast-name">
                            <div className="dashboard-main-podcast-creator align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nathaniel Bassey</div>
                            <div className="dashboard-main-podcast-name align-text-center dashboard-main-opensans-normal-pale-sky-12px">Hallelujah</div>
                          </div>
                        </div>
                        <div className="dashboard-main-podcast-stats">
                          <div className="dashboard-main-podcast-genre align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Gospel Hit</div>
                          <div className="dashboard-main-podcast-view-count align-text-center dashboard-main-opensans-normal-eerie-black-12px">24k Views</div>
                        </div>
                      </div>
                      <div className="dashboard-main-card-frame">
                        <div className="dashboard-main-card-podcast-info">
                          <img className="dashboard-main-avatars-web-7" src={Background} alt="background"/>
                          <div className="dashboard-main-podcast-creator-and-podcast-name">
                            <div className="dashboard-main-podcast-creator align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nathaniel Bassey</div>
                            <div className="dashboard-main-podcast-name align-text-center dashboard-main-opensans-normal-pale-sky-12px">Hallelujah</div>
                          </div>
                        </div>
                        <div className="dashboard-main-podcast-stats">
                          <div className="dashboard-main-podcast-genre align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Gospel Hit</div>
                          <div className="dashboard-main-podcast-view-count align-text-center dashboard-main-opensans-normal-eerie-black-12px">24k Views</div>
                        </div>
                      </div>
                </div>
            </div>
            <div className="dashboard-main-top-podcasters">
                <p className="dashboard-main-section-header">Top Podcasters</p>
                <div className="dashboard-main-top-podcasters-card-container">
                    <div className="dashboard-main-top-podcaster-card">
                        <img className="dashboard-main-avatars-web-8" src={Background} alt="background" />
                        <div className="dashboard-main-podcaster-name-and-email">
                          <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nse-Abasi Etim</div>
                          <div className="dashboard-main-podcaster-email align-text-center dashboard-main-opensans-normal-pale-sky-12px">@thepundit1</div>
                        </div>
                        <div className="dashboard-main-podcast-title-and-badge">
                          <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">
                            Git and Version control
                          </div>
                          <div className="dashboard-main-badge-container">
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">Tech</div>
                            </div>
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">science</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-main-top-podcaster-card">
                        <img className="dashboard-main-avatars-web-8" src={Background} alt="background" />
                        <div className="dashboard-main-podcaster-name-and-email">
                          <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nse-Abasi Etim</div>
                          <div className="dashboard-main-podcaster-email align-text-center dashboard-main-opensans-normal-pale-sky-12px">@thepundit1</div>
                        </div>
                        <div className="dashboard-main-podcast-title-and-badge">
                          <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">
                            Git and Version control
                          </div>
                          <div className="dashboard-main-badge-container">
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">Tech</div>
                            </div>
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">science</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-main-top-podcaster-card">
                        <img className="dashboard-main-avatars-web-8" src={Background} alt="background" />
                        <div className="dashboard-main-podcaster-name-and-email">
                          <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nse-Abasi Etim</div>
                          <div className="dashboard-main-podcaster-email align-text-center dashboard-main-opensans-normal-pale-sky-12px">@thepundit1</div>
                        </div>
                        <div className="dashboard-main-podcast-title-and-badge">
                          <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">
                            Git and Version control
                          </div>
                          <div className="dashboard-main-badge-container">
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">Tech</div>
                            </div>
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">science</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-main-top-podcaster-card">
                        <img className="dashboard-main-avatars-web-8" src={Background} alt="background" />
                        <div className="dashboard-main-podcaster-name-and-email">
                          <div className="dashboard-main-podcaster-name align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">Nse-Abasi Etim</div>
                          <div className="dashboard-main-podcaster-email align-text-center dashboard-main-opensans-normal-pale-sky-12px">@thepundit1</div>
                        </div>
                        <div className="dashboard-main-podcast-title-and-badge">
                          <div className="dashboard-main-podcast-title align-text-center dashboard-main-opensans-semi-bold-eerie-black-14px">
                            Git and Version control
                          </div>
                          <div className="dashboard-main-badge-container">
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">Tech</div>
                            </div>
                            <div className="dashboard-main-badge-pill-green-small">
                              <div className="dashboard-main-green align-text-center dashboard-main-opensans-semi-bold-eucalyptus-10px">science</div>
                            </div>
                          </div>
                        </div>
                      </div>



                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default DashboardMain
function Frame162(props) {
  const { icOutlineSpaceDashboard, dashBoard, className, onClick } = props;

  return (
    <div onClick={onClick}  className={`frame-8133 ${className || ""}`}>
      <img className="iconoutlinelogout" src={icOutlineSpaceDashboard} alt="icon/outline/logout" />
      <div className="logoutopensans-normal-solitude-14px">
        {dashBoard}
      </div>
    </div>
  );
}

function Frame1(props) {
  const { dashBoard, className } = props;

  return (
    <div className={`frame-1 ${className || ""}`}>
      <img className="no-outline" src={DashboardOutline} alt="ic:outline-space-dashboard" />
      <div className="dash-board opensans-normal-solitude-14px">
        {dashBoard}
      </div>
    </div>
  );
}

function Frame8136(props) {
  const { profile } = props;

  return (
    <div className="frame-8131">
      <img className="iconoutlinesound" src={SoundIcon} alt="icon/outline/sound" />
      <div className="my-podcasts opensans-normal-solitude-14px">
        {profile}
      </div>
    </div>
  );
}
