import React from 'react'
import { Link } from 'react-router-dom'
import './dashboardStylesheets/DashboardDiscovery.css'
import Logout from './Logout'
import Rectangle from "../../assets/dashboardAssets/rectangle-99.png";
import Rectangle2 from "../../assets/dashboardAssets/rectangle-3140-2.png";
import BitcoinExchange from "../../assets/dashboardAssets/bitcoin-exchange-rate-1.png";
import Arrow4 from "../../assets/dashboardAssets/icon-outline-arrow-down-4.svg";
import Arrow5 from "../../assets/dashboardAssets/icon-outline-arrow-down-5.svg";
import IconOutline from "../../assets/dashboardAssets/icon-outline-menuv-4.svg";
import PlayBtn from "../../assets/dashboardAssets/play-button.svg";
import DesignIcon from "../../assets/dashboardAssets/iconoir-design-nib-3.svg";
import Bitcoinsvg from "../../assets/dashboardAssets/ic-sharp-currency-bitcoin-1.svg";
import Carbon from "../../assets/dashboardAssets/carbon-development-1.svg";
import Friends from "../../assets/dashboardAssets/friends.svg";
import Background from "../../assets/dashboardAssets/Background.svg";

const DashboardDiscovery = () => {
  return (
    <div>
      <div className="dashboard-discovery-container">
              <div className="frame-8149 screen">
        <div className="overlap-group1">
          <div className="rectangle-1"></div>
          <div className="version-100 valign-text-middle opensans-normal-purple-heart-16px">
            Version 1.0.0
          </div>
          <Link to="#">
          <Frame162 icOutlineSpaceDashboard="./assets/dashboardAssets/icon-outline-logout-1.svg" dashBoard="Logout" />
          </Link>
          <Link to="/dashboard">
          <Frame1 dashBoard="Dash Board" />
          </Link>
          <Link to="/mypodcasts">
          <Frame8136 profile="My Podcasts" />
          </Link>
          <Link to="#">
          <Frame162
            icOutlineSpaceDashboard="./assets/dashboardAssets/carbon-settings.svg"
            dashBoard="Settings"
            className="frame-8134"
          />
          </Link>
          <Link to="/profile">
          <Frame1 dashBoard="Profile" className="frame-8136" />
          </Link>
          <div className="overview opensans-normal-cornflower-14px">
            OVERVIEW
          </div>
          <div className="tools opensans-normal-cornflower-14px">
            TOOLS
          </div>
          <div className="frame-2"></div>
          <div className="frame-8146">
            <div className="overlap-group">
              <div className="celecast timesnewroman-bold-white-18px">
                Celecast
              </div>
              <img className="rectangle-99" src={Rectangle} alt="Rectangle 99" />
            </div>
            <div className="creator poppins-medium-amber-18px">
              Creator
            </div>
          </div>
        </div>
      </div>
        <div className="dashboard-discovery-content">
            <div className="dashboard-discovery-main-content">
                <p>Listen to awesome content</p>
                <div className="dashboard-discovery-upgrade-plan-popup">
                    <div className="dashboard-discovery-flex-col">
                        <div className="dashboard-discovery-upgrade-your-plan dashboard-discovery-flex-align-text-center">Upgrade your plan</div>
                        <p className="dashboard-discovery-x70-discounts-for-1-year-subscription dashboard-discovery-flex-align-text-center">
                          <span>
                            <span className="dashboard-discovery-opensans-normal-tuna-14px">70%
                            </span>
                            <span className="dashboard-discovery-opensans-normal-pale-sky-14px">discounts for </span>
                            <span className="dashboard-discovery-opensans-normal-tuna-14px">1
                            </span>
                            <span className="dashboard-discovery-opensans-normal-pale-sky-14px"> year subscription</span>
                          </span>
                        </p>
                        <div className="dashboard-discovery-button-container">
                          <div className="dashboard-discovery-upgrade-button">
                            <div className="dashboard-discovery-button-text">
                              <div className="dashboard-discovery-upgrade-button-text dashboard-discovery-opensans-">Upgrade</div>
                            </div>
                          </div>
                          <div className="dashboard-discovery-remind-me-later-button">
                            <div className="dashboard-discovery-button-text">
                              <p className="dashboard-discovery-remind-me-later-button-text dashboard-discovery-opensans-">Remind me later</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img className="dashboard-discovery-upgrade-plan-img" src={BitcoinExchange} alt="bitcoin" />
                </div>
                <div className="dashboard-discovery-explore">
                    <p className="dashboard-discovery-opensans-semi-bold-scarpa-flow-20px">Explore</p>
                    <div className="dashboard-discovery-explore-options">
                        <div className="dashboard-discovery-podcast-genre">

                            <div className="dashboard-discovery-recently-played dashboard-discovery-opensans- active">Recently played</div>
                            <div className="dashboard-discovery-top-podcasts dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-regent-gray-14px">Top podcasts</div>
                            <div className="dashboard-discovery-new-podcasts dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-regent-gray-14px">New podcasts</div>
                            <div className="dashboard-discovery-bestsellers dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-regent-gray-14px">Bestsellers</div>

                          </div>
                          <div>
                            <img src={Arrow4} alt="arrow 4" />
                            <img src={Arrow5} alt="arrow 5" />
                            {/* <img /> */}
                          </div>

                    </div>
                    <div className="carousel"data-flickity='{ "freeScroll": true }'>
                        <div className="carousel-cell">
                            <div className="dashboard-discovery-card">
                                <img className="dashboard-discovery-card-img" src={Rectangle2} alt="rectangle 2" />
                                <div>
                                    <div className="dashboard-discovery-flex-row card-header">
                                        <p className="dashboard-discovery-opensans-semi-bold-scarpa-flow-16px">Best Mobile Strategy</p>
                                        <img src={IconOutline} alt="icon outline" />
                                    </div>
                                    <p className="dashboard-discovery-opensans-normal-regent-gray-12px"> By Nse-Abasi Etim</p>
                                    <div className="dashboard-discovery-badges">
                                        <div className="dashboard-discovery-badge-green dashboard-discovery-opensans-semi-bold-eucalyptus-10px">Tech</div>
                                        <div className="dashboard-discovery-badge-blue circularstd-medium-endeavour-10px">Software Dev</div>
                                    </div>
                                    <div className="dashboard-discovery-flex-row time-play">
                                        <p className="dashboard-discovery-opensans-normal-pale-sky-14px">1h 34m</p>
                                        <img src={PlayBtn} alt="play btn" /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell">
                            <div className="dashboard-discovery-card">
                                <img className="dashboard-discovery-card-img" src={Rectangle2} alt="rectangle 2" />
                                <div>
                                    <div className="dashboard-discovery-flex-row dashboard-discovery-card-header">
                                        <p className="dashboard-discovery-opensans-semi-bold-scarpa-flow-16px">Best Mobile Strategy</p>
                                        <img src={IconOutline} alt="icon outline" />
                                    </div>
                                    <p className="dashboard-discovery-opensans-normal-regent-gray-12px"> By Nse-Abasi Etim</p>
                                    <div className="dashboard-discovery-badges">
                                        <div className="dashboard-discovery-badge-green dashboard-discovery-opensans-semi-bold-eucalyptus-10px">Tech</div>
                                        <div className="dashboard-discovery-badge-blue dashboard-discovery-circularstd-medium-endeavour-10px">Software Dev</div>
                                    </div>
                                    <div className="dashboard-discovery-flex-row time-play">
                                        <p className="dashboard-discovery-opensans-normal-pale-sky-14px">1h 34m</p>
                                        <img src={PlayBtn} alt="play button" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell">
                            <div className="dashboard-discovery-card">
                                <img className="dashboard-discovery-card-img" src={Rectangle2} alt="rectangle 2" />
                                <div>
                                    <div className="dashboard-discovery-flex-row card-header">
                                        <p className="dashboard-discovery-opensans-semi-bold-scarpa-flow-16px">Best Mobile Strategy</p>
                                        <img src={IconOutline} alt="Icon outline" />
                                    </div>
                                    <p className="dashboard-discovery-opensans-normal-regent-gray-12px"> By Nse-Abasi Etim</p>
                                    <div className="dashboard-discovery-badges">
                                        <div className="dashboard-discovery-badge-green dashboard-discovery-opensans-semi-bold-eucalyptus-10px">Tech</div>
                                        <div className="dashboard-discovery-badge-blue dashboard-discovery-circularstd-medium-endeavour-10px">Software Dev</div>
                                    </div>
                                    <div className="dashboard-discovery-flex-row time-play">
                                        <p className="dashboard-discovery-opensans-normal-pale-sky-14px">1h 34m</p>
                                        <img src={PlayBtn} alt="Play button" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell">
                            <div className="dashboard-discovery-card">
                                <img className="dashboard-discovery-card-img" src={Rectangle2} alt="rectangle 2" />
                                <div>
                                    <div className="dashboard-discovery-flex-row card-header">
                                        <p className="dashboard-discovery-opensans-semi-bold-scarpa-flow-16px">Best Mobile Strategy</p>
                                        <img src={IconOutline} alt="Icon outline" />
                                    </div>
                                    <p className="dashboard-discovery-opensans-normal-regent-gray-12px"> By Nse-Abasi Etim</p>
                                    <div className="dashboard-discovery-badges">
                                        <div className="dashboard-discovery-badge-green dashboard-discovery-opensans-semi-bold-eucalyptus-10px">Tech</div>
                                        <div className="dashboard-discovery-badge-blue dashboard-discovery-circularstd-medium-endeavour-10px">Software Dev</div>
                                    </div>
                                    <div className="dashboard-discovery-flex-row dashboard-discovery-time-play">
                                        <p className="dashboard-discovery-opensans-normal-pale-sky-14px">1h 34m</p>
                                        <img src={PlayBtn} alt="Play button" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell">
                            <div className="dashboard-discovery-card">
                                <img className="dashboard-discovery-card-img" src={Rectangle2} alt="rectangle 2" />
                                <div>
                                    <div className="dashboard-discovery-flex-row card-header">
                                        <p className="dashboard-discovery-opensans-semi-bold-scarpa-flow-16px">Best Mobile Strategy</p>
                                        <img src={IconOutline} alt="Icon outline" />
                                    </div>
                                    <p className="dashboard-discovery-opensans-normal-regent-gray-12px"> By Nse-Abasi Etim</p>
                                    <div className="dashboard-discovery-badges">
                                        <div className="dashboard-discovery-badge-green dashboard-discovery-opensans-semi-bold-eucalyptus-10px">Tech</div>
                                        <div className="dashboard-discovery-badge-blue circularstd-medium-endeavour-10px">Software Dev</div>
                                    </div>
                                    <div className="dashboard-discovery-flex-row time-play">
                                        <p className="dashboard-discovery-opensans-normal-pale-sky-14px">1h 34m</p>
                                        <img src={PlayBtn} alt="Play button" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="dashboard-discovery-top-categories">
                    <p className="dashboard-discovery-opensans-semi-bold-scarpa-flow-20px">Top Categories</p>
                    <div className="dashboard-discovery-carousel" data-flickity='{ "freeScroll": true }'>
                        <div className="carousel-cell-tab">
                            <div className="dashboard-discovery-tab">
                                <div className="dashboard-discovery-tab-div-1">
                                    <div className="dashboard-discovery-tab-info">
                                        <div className="dashboard-discovery-tab-img-div"><img className="dashboard-discovery-tab-img" src={DesignIcon} alt="Design icon" /></div>
                                        <div>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-scarpa-flow-12px">Product Design</p>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-scarpa-flow-10px">151 podcasts</p>
                                        </div>
                                    </div>

                                    <div className="dashboard-discovery-tab-highlight"></div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell-tab">
                            <div className="dashboard-discovery-tab">
                                <div className="dashboard-discovery-tab-div-1">
                                    <div className="dashboard-discovery-tab-info">
                                        <div className="dashboard-discovery-tab-img-div"><img className="dashboard-discovery-tab-img" src={Bitcoinsvg} alt="bitcoin svg" /></div>

                                        <div>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-scarpa-flow-12px">Cryptocurrency</p>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-scarpa-flow-10px">151 podcasts</p>
                                        </div>
                                    </div>

                                    <div className="dashboard-discovery-tab-highlight"></div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell-tab">
                            <div className="dashboard-discovery-tab">
                                <div className="dashboard-discovery-tab-div-1">
                                    <div className="dashboard-discovery-tab-info">
                                        <div className="dashboard-discovery-tab-img-div"><img className="dashboard-discovery-tab-img" src={Carbon} alt="carbon" /></div>
                                        <div>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-scarpa-flow-12px">Software Development</p>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-scarpa-flow-10px">151 podcasts</p>
                                        </div>
                                    </div>
                                    <div className="dashboard-discovery-tab-highlight"></div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell-tab">
                            <div className="dashboard-discovery-tab">
                                <div className="dashboard-discovery-tab-div-1">
                                    <div className="dashboard-discovery-tab-info">
                                        <div className="dashboard-discovery-tab-img-div"><img className="dashboard-discovery-tab-img" src={DesignIcon} alt="Design icon" /></div>

                                        <div>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-scarpa-flow-12px">Product Design</p>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-scarpa-flow-10px">151 podcasts</p>
                                        </div>
                                    </div>
                                    <div className="dashboard-discovery-tab-highlight"></div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-cell-tab">
                            <div className="dashboard-discovery-tab">
                                <div className="dashboard-discovery-tab-div-1">
                                    <div className="dashboard-discovery-tab-info">
                                        <div className="dashboard-discovery-tab-img-div"><img className="dashboard-discovery-tab-img" src={DesignIcon} alt="Design icon" /></div>

                                        <div>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-scarpa-flow-12px">Product Design</p>
                                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-scarpa-flow-10px">151 podcasts</p>
                                        </div>
                                    </div>
                                    <div className="dashboard-discovery-tab-highlight"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-discovery-sub-content">
                <div className="dashboard-discovery-friends-activity">
                    <div className="dashboard-discovery-friends-activity-header-1"><h4>Friends activity</h4><img src={Friends} alt="friends" /></div>
                    <hr />
                    <div className="dashboard-discovery-friend-podcast-audio">
                        <div className="dashboard-discovery-friend-podcast-img-div">
                            <img src={Background} alt="Background" />
                        </div>
                        <div className="dashboard-discovery-friend-podcast-info">
                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-eerie-black-14px">Nse-Abasi Etim</p>
                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-pale-sky-12px">Product Design Essentials</p>
                        </div>
                    </div>
                    <div className="dashboard-discovery-friend-podcast-audio">
                        <div className="dashboard-discovery-friend-podcast-img-div">
                            <img src={Background} alt="Background" />
                        </div>
                        <div className="dashboard-discovery-friend-podcast-info">
                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-eerie-black-14px">Nse-Abasi Etim</p>
                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-pale-sky-12px">Product Design Essentials</p>
                        </div>
                    </div>
                    <div className="dashboard-discovery-friend-podcast-audio">
                        <div className="dashboard-discovery-friend-podcast-img-div">
                            <img src={Background} alt="Background" />
                        </div>
                        <div className="dashboard-discovery-friend-podcast-info">
                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-semi-bold-eerie-black-14px">Nse-Abasi Etim</p>
                            <p className="dashboard-discovery-flex-align-text-center dashboard-discovery-opensans-normal-pale-sky-12px">Product Design Essentials</p>
                        </div>
                    </div>
                    
                </div>
                <div className="dashboard-discovery-activity-statistics">
                    <div className="dashboard-discovery-sub-content-header-2">
                        <h4>Your Activity Statistics</h4> 
                    </div>
                    <div className="dashboard-discovery-stat">
                        <div className="dashboard-discovery-day-duration-div">
                            <div className="dashboard-discovery-opensans-semi-bold-blue-16px">Today</div>
                            <div className="dashboard-discovery-duration dashboard-discovery-opensans-bold-white-16px">
                                <span className="dashboard-discovery-opensans-bold-scarpa-flow-16px">325</span><span className="dashboard-discovery-opensans-semi-bold-blue-16px">&nbsp;</span>
                                <span className="dashboard-discovery-opensans-semi-bold-heather-14px">min</span>
                            </div>
                        </div>
                        <div style={{width:'124px', borderRadius:'15px'}}className="dashboard-discovery-w3-border">
                            <div className="dashboard-discovery-w3-blue" style={{height:'7px', width:'60%', borderRadius:'15px'}}></div>
                        </div>
                        <p className="dashboard-discovery-stats-percentage-blue">60%</p>

                    </div>
                    <div className="dashboard-discovery-stat">
                        <div className="dashboard-discovery-day-duration-div">
                            <div className="dashboard-discovery-opensans-semi-bold-heather-16px">Thursday</div>
                            <div className="dashboard-discovery-duration dashboard-discovery-opensans-bold-white-16px">
                                <span className="dashboard-discovery-opensans-bold-scarpa-flow-16px">325</span><span className="dashboard-discovery-opensans-semi-bold-blue-16px">&nbsp;</span>
                                <span className="dashboard-discovery-opensans-semi-bold-heather-14px">min</span>
                            </div>
                        </div>
                        <div style={{width:'124px', borderRadius:'15px'}} className="dashboard-discovery-w3-border">
                            <div className="dashboard-discovery-w3-green" style={{height:'7px',width:'40%', borderRadius:'15px'}}></div>
                        </div>
                        <p className="dashboard-discovery-stats-percentage-green">40%</p>

                    </div>
                    <div className="dashboard-discovery-stat">
                        <div className="dashboard-discovery-day-duration-div">
                            <div className="dashboard-discovery-opensans-semi-bold-heather-16px">Monday</div>
                            <div className="dashboard-discovery-duration dashboard-discovery-opensans-bold-white-16px">
                                <span className="dashboard-discovery-opensans-bold-scarpa-flow-16px">325</span><span className="dashboard-discovery-opensans-semi-bold-blue-16px">&nbsp;</span>
                                <span className="dashboard-discovery-opensans-semi-bold-heather-14px">min</span>
                            </div>
                        </div>
                        <div style={{width:'124px',borderRadius:'15px'}}className="dashboard-discovery-w3-border">
                            <div className="dashboard-discovery-w3-yellow" style={{height:'7px',width:'70%',borderRadius:'15px'}}></div>
                        </div>
                        <p className="dashboard-discovery-stats-percentage-yellow">70%</p>

                    </div>
                    <div className="dashboard-discovery-stat">
                        <div className="dashboard-discovery-day-duration-div">
                            <div className="dashboard-discovery-opensans-semi-bold-heather-16px">Wednesday</div>
                            <div className="dashboard-discovery-duration dashboard-discovery-opensans-bold-white-16px">
                                <span className="dashboard-discovery-opensans-bold-scarpa-flow-16px">325
                                </span>
                                <span className="dashboard-discovery-opensans-semi-bold-blue-16px">&nbsp;
                                </span>
                                <span className="dashboard-discovery-opensans-semi-bold-heather-14px">min</span>
                            </div>
                        </div>
                        <div style={{width:'124px', borderRadius:'15px'}} className="dashboard-discovery-w3-border">
                            <div className="dashboard-discovery-w3-green" style={{height:'7px',width:'90%',borderRadius:'15px'}}></div>
                        </div>
                        <p className="dashboard-discovery-stats-percentage-green">90%</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default DashboardDiscovery
function Frame162(props) {
    const { icOutlineSpaceDashboard, dashBoard, className } = props;
  
    return (
      <div className={`frame-8133 ${className || ""}`}>
        <img className="iconoutlinelogout" src={icOutlineSpaceDashboard} alt="icon/outline/logout" />
        <div className="logoutopensans-normal-solitude-14px">
          {dashBoard}
        </div>
      </div>
    );
  }
  
  function Frame1(props) {
    const { dashBoard, className } = props;
  
    return (
      <div className={`frame-1 ${className || ""}`}>
        <img className="no-outline" src="./assets/dashboardAssets/ic-outline-space-dashboard.svg" alt="ic:outline-space-dashboard" />
        <div className="dash-boardopensans-normal-solitude-14px">
          {dashBoard}
        </div>
      </div>
    );
  }
  
  function Frame8136(props) {
    const { profile } = props;
  
    return (
      <div className="frame-8131">
        <img className="iconoutlinesound" src="./assets/dashboardAssets/icon-outline-sound.svg" alt="icon/outline/sound" />
        <div className="my-podcastsopensans-normal-solitude-14px">
          {profile}
        </div>
      </div>
    );
  }