import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import  userReviews  from '../../data/usersReview';
import carousel from "./Carousel.module.css";

const Carousel = () => {
  const [position, setPosition] = useState(1);

  const handleSwipe = (direction) => {
    if (direction === 'left' && position < 6) {
      setPosition(position + 1);
        document.documentElement.style.setProperty('--position', `${position}`)
    } else if (direction === 'right' && position > 1) {
      setPosition(position - 1);
      document.documentElement.style.setProperty('--position', `${position}`)
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    swipeDuration: Infinity,
    trackTouch: true,

  });
  
    return (
        
      <div className={carousel.body} {...swipeHandlers}>
        <main id="carousel" style ={{'--position': position}}  >
            {userReviews.map(review => {
                return (
                <div className={carousel.item}>
                <div className={`${carousel.card-carousel} ${carousel.card_medium}`}>
                <div className={carousel.column}>
                    <p>
                    {review.userRemark}
                    </p>
                    <div className={`row align-center ${carousel.user_details}`}>
                    <img className={carousel.userpic} alt={review.userImage.alt} src={review.userImage.src} />
                    <div className={carousel.column}>
                        <p className={carousel.no_margin}>{review.userName}</p>
                        <p className={`${carousel.no_margin} ${carousel.grey}`}>{review.userTitle}</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>)
            })}
        </main>
      </div>
 
    );
  };
  
  export default Carousel;
  