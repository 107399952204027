import axios from "axios";
import { baseUrl } from "../constants";

const request = axios.create({
  baseURL: baseUrl,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

export default request;
