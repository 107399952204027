import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './dashboardStylesheets/DashboardPodcasts.css'
import Logout from './Logout'
import Rectangle from "../../assets/dashboardAssets/rectangle-99.png";
import Subtract from "../../assets/dashboardAssets/subtract.svg";
import SearchIcon from "../../assets/dashboardAssets/icon-outline-search.svg";
import AddIcon from "../../assets/dashboardAssets/icon-outline-add.svg";
import Background from "../../assets/dashboardAssets/Background.svg";
import DashboardOutline from "../../assets/dashboardAssets/ic-outline-space-dashboard.svg";
import SoundIcon from "../../assets/dashboardAssets/icon-outline-sound.svg";

const DashboardPodcasts = () => {
    const percentage = 66;
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = () => {
      setOpenModal(true)
    }
    const handleCloseModal = () => {
      setOpenModal(false)
    }
  return (
    <div>
        <Logout open={openModal} close={handleCloseModal}/> 
      <div className="dashboard-podcasts-container">
      <div className="frame-8149 screen">
        <div className="overlap-group1">
          <div className="rectangle-1"></div>
          <div className="version-100 valign-text-middle opensans-normal-purple-heart-16px">
            Version 1.0.0
          </div>
          <Link to="#">
          <Frame162 onClick={handleOpenModal} icOutlineSpaceDashboard="./assets/dashboardAssets/icon-outline-logout-1.svg" dashBoard="Logout" />
          </Link>
          <Link to="/dashboard">
          <Frame1 dashBoard="Dash Board" />
          </Link>
          <Link to="/mypodcasts">
          <Frame8136 profile="My Podcasts" />
          </Link>
          <Link to="#">
          <Frame162
            icOutlineSpaceDashboard="./assets/dashboardAssets/carbon-settings.svg"
            dashBoard="Settings"
            className="frame-8134"
          />
          </Link>
          <Link to="/profile">
          <Frame1 dashBoard="Profile" className="frame-8136" />
          </Link>
          <div className="overview opensans-normal-cornflower-14px">
            OVERVIEW
          </div>
          <div className="tools opensans-normal-cornflower-14px">
            TOOLS
          </div>
          <div className="frame-2"></div>
          <div className="frame-8146">
            <div className="overlap-group">
              <div className="celecast timesnewroman-bold-white-18px">
                Celecast
              </div>
              <img className="rectangle-99" src={Rectangle} alt="Rectangle 99" />
            </div>
            <div className="creator poppins-medium-amber-18px">
              Creator
            </div>
          </div>
        </div>
      </div>
        <div className="dashboard-podcasts-main-content">
            <div className="dashboard-podcasts-banner">
                <div className="dashboard-podcasts-banner-icon-div">
                  <img className="dashboard-podcasts-banner-img" src={Subtract} alt="Subtract" />
                </div>
                <div className="dashboard-podcasts-banner-title align-text-center">Info.</div>
                <p className="dashboard-podcasts-banner-text align-text-center">View, manage and create new podcasts from here</p>
              </div>
              <div className="dashboard-podcasts-podcast-menu-and-search-bar">
                <div className="dashboard-podcasts-podcast-menu">
                    <div className="dashboard-podcasts-all-podcasts align-text-center active">All podcasts</div>
                    <div className="dashboard-podcasts-podcast-menu-item align-text-center  dashboard-podcasts-opensans-semi-bold-regent-gray-14px">Drafts</div>
                    <div className="dashboard-podcasts-podcast-menu-item align-text-center  dashboard-podcasts-opensans-semi-bold-regent-gray-14px">Published</div>
                </div>
                <div className="dashboard-podcasts-podcast-search-bar">
                    <img className="dashboard-podcasts-icon-outline-search" src={SearchIcon} alt="Icon/Outline/Search" />
                    <input type="search" placeholder="Search Podcast"/>
                </div>
              </div>
              <div className="dashboard-podcasts-podcast-header-and-create-button"><p className="dashboard-podcasts-align-text-center all-podcast-header">All Podcasts</p><button className="dashboard-podcasts-create-podcast-button"><img src={AddIcon} alt="add icon" /> Create Podcast</button></div>
              <table className="dashboard-podcasts-all-podcast-table">
                <thead>
                    <tr className="dashboard-podcasts-header-row">
                        <th>TITLE</th>
                        <th>DOWNLOADS</th>
                        <th>STATUS</th>
                        <th>RELEASE DATE</th>
                    </tr>
                </thead>
                <tbody>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-green">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-eucalyptus-12px">Published</div>
                          </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-green">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-eucalyptus-12px">Published</div>
                          </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-green">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-eucalyptus-12px">Published</div>
                          </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background" />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                <tr className="dashboard-podcasts-table-data">
                    <td className="dashboard-podcasts-podcast-title-info">
                        <img src={Background} alt="Background"  />
                        <div className="dashboard-podcasts-name-and-creator">
                            <div className="dashboard-podcasts-podcast-name align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">Kingdom Worship</div>
                            <div className="dashboard-podcasts-podcast-creator align-text-center  dashboard-podcasts-opensans-normal-scarpa-flow-12px">RCCG Choir</div>
                        </div>
                    </td>
                    <td>
                        <div className="dashboard-podcasts-podcast-downloads lign-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">250K</div>
                    </td>
                    <td className="dashboard-podcasts-status-badge">
                        <div className="dashboard-podcasts-badge-pill-yellow">
                            <div className="dashboard-podcasts-status-text align-text-center  dashboard-podcasts-opensans-semi-bold-tahiti-gold-12px">Drafts</div>
                        </div>
                    </td>
                    <td className="dashboard-podcasts-podcast-release-date">
                        <div className="dashboard-podcasts-release-date align-text-center  dashboard-podcasts-opensans-semi-bold-scarpa-flow-14px">22.02.22</div>
                    </td>
                </tr>
                </tbody>
              </table>
        </div>

        <div className="dashboard-podcasts-sub-contents">
            <div className="dashboard-podcasts-audience-satisfaction-container">
                <p className="dashboard-podcasts-audience-satisfaction-text">Audience Satisfaction</p>
                <div className="dashboard-podcasts-audience-satisfaction-content-div">
                    <div className="dashboard-podcasts-visual-rating">
                       <div>
                        <div style={{marginLeft: 50, width: 200, height: 200 }} id="progress">
                                <CircularProgressbar value={percentage} text={`${percentage}%`} />;
                        </div>
                       </div>
                       <p className="dashboard-podcasts-based-on-likes-dislikes-text">Based on likes / dislikes</p>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div className="dashboard-podcasts-celecast-newsletter-container">
                <div className="dashboard-podcasts-newsletter-frame">
                    <div className="dashboard-podcasts-newsletter-content">
                      <div className="dashboard-podcasts-newsletter-headinig  dashboard-podcasts-opensans-normal-white-16px">Celecast newsleter</div>
                      <p className="dashboard-podcasts-newsletter-message">Learn how to increase <br />your reach and convert<br />more visitors to listeners</p>
                      <p className="dashboard-podcasts-subscribe-text">
                        Subscribe to our newsletter to receive tips<br />on how to increase your reach and <br />convert visitors to listeners.
                      </p>
                      <p className="dashboard-podcasts-learn-more-text  dashboard-podcasts-opensans-normal-white-16px">Learn more</p>
                    </div>
                  </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default DashboardPodcasts
function Frame162(props) {
    const { icOutlineSpaceDashboard, dashBoard, className, onClick } = props;
  
    return (
      <div onClick={onClick}  className={`frame-8133 ${className || ""}`}>
        <img className="iconoutlinelogout" src={icOutlineSpaceDashboard} alt="icon/outline/logout" />
        <div className="logoutopensans-normal-solitude-14px">
          {dashBoard}
        </div>
      </div>
    );
  }
  
  function Frame1(props) {
    const { dashBoard, className } = props;
  
    return (
      <div className={`frame-1 ${className || ""}`}>
        <img className="no-outline" src={DashboardOutline} alt="ic:outline-space-dashboard" />
        <div className="dash-boardopensans-normal-solitude-14px">
          {dashBoard}
        </div>
      </div>
    );
  }
  
  function Frame8136(props) {
    const { profile } = props;
  
    return (
      <div className="frame-8131 active-link">
        <img className="iconoutlinesound" src={SoundIcon} alt="icon/outline/sound" />
        <div className="my-podcastsopensans-normal-solitude-14px">
          {profile}
        </div>
      </div>
    );
  }