import * as MuiMaterial from "@mui/material";
import * as Antd from "antd";
import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import * as EmotionReact from "@emotion/react";
import * as EmotionStyled from "@emotion/styled";
import * as MuiIconsMaterial from "@mui/icons-material";
import "./profileStylesheet/ProfileFilled.css"
import * as AntDesignIcons from "@ant-design/icons";
import { Link } from "react-router-dom";
import Logout from './Logout'
import Rectangle from "../../assets/dashboardAssets/rectangle-99.png";
import Rectangle2 from "../../assets/dashboardAssets/rectangle-3135.svg";
import Carbon from "../../assets/dashboardAssets/carbon-settings.svg";
import LogoutIcon from "../../assets/dashboardAssets/icon-outline-logout-1.svg";
import Bleed from "../../assets/dashboardAssets/section-bleed-1.svg";
import Bleed2 from "../../assets/dashboardAssets/section-bleed-2.svg";
import ProfileCircle from "../../assets/dashboardAssets/icon-bold-profile-circle.svg";
import Profile from "../../assets/dashboardAssets/icon-outline-profile-1.svg";
import Location from "../../assets/dashboardAssets/icon-bold-location.svg";
import Line from "../../assets/dashboardAssets/line-1.svg";
import DashboardOutline from "../../assets/dashboardAssets/ic-outline-space-dashboard.svg";
import SoundIcon from "../../assets/dashboardAssets/icon-outline-sound.svg";
import SoundIcon2 from "../../assets/dashboardAssets/icon-outline-sound-1.svg";

function App() {
  const [openModal, setOpenModal] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: null,
    username : null, 
    first_name :null, 
    last_name :null, 
    email :null, 
    dob :null,
    country:null, 
    gender: null, 
    phone_number: null, 
    profile_picture_url: null, 
    email_is_verified: null, 
    suspended: null
  })

  useEffect(() => {
    const fetchUserDetails = async() => {
      try {
        const response = await 
          fetch('https://api.celecast.co/users/get_user_profile/', {
            method: 'GET',
            headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        setUserInfo({
          id: data.id,
          username : data.username, 
          first_name :data.first_name, 
          last_name :data.last_name, 
          email :data.email, 
          dob :data.dob,
          country:data.country, 
          gender: data.gender, 
          phone_number: data.phone_number, 
          profile_picture_url: data.profile_picture_url, 
          email_is_verified: data.email_is_verified, 
          suspended: data.suspended});
      } catch (error) {
        console.log("Error:" , error);
        console.log(localStorage.getItem('token'))
      }
     };
     fetchUserDetails();

      
  }, []);


  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <Antd.ConfigProvider>
      <MuiMaterial.ThemeProvider theme={theme}>
        <div className="profile-filled-container-center-horizontal">
        <Logout open={openModal} close={handleCloseModal}/>  
          <div className="profile-filled-profile-filled screen">
          <div className="frame-8149 screen">
        <div className="overlap-group1">
          <div className="rectangle-1"></div>
          <div className="version-100 valign-text-middle opensans-normal-purple-heart-16px">
            Version 1.0.0
          </div>
          
          <Frame162 onClick={handleOpenModal} icOutlineSpaceDashboard={LogoutIcon} dashBoard="Logout" />
          
          <Link to="/dashboard">
          <Frame1 dashBoard="Dash Board" />
          </Link>
          <Link to="/mypodcasts">
          <Frame8136 profile="My Podcasts" />
          </Link>
          <Link to="#">
          <Frame162
            icOutlineSpaceDashboard={Carbon}
            dashBoard="Settings"
            className="frame-8134"
          />
          </Link>
          <Link to="/profile">
          <Frame1 dashBoard="Profile" className="frame-8136 active-link" />
          </Link>
          <div className="overview opensans-normal-cornflower-14px">
            OVERVIEW
          </div>
          <div className="tools opensans-normal-cornflower-14px">
            TOOLS
          </div>
          <div className="frame-2"></div>
          <div className="frame-8146">
            <div className="overlap-group">
              <div className="celecast timesnewroman-bold-white-18px">
                Celecast
              </div>
              <img className="rectangle-99" src={Rectangle} alt="Rectangle 99" />
            </div>
            <div className="creator poppins-medium-amber-18px">
              Creator
            </div>
          </div>
        </div>
      </div> 
            <div className="profile-filled-flex-col">
              <Frame8143
 topNavBarContentProps={frame8143Data.topNavBarContentProps} />
              <div className="profile-filled-profile-content-internal-container">
                <div className="profile-filled-flex-col-1 profile-filled-opensans-semi-bold-pale-sky-14px">
                  {userInfo.profile_picture_url ? 
                    (<img className="profile-filled-profile-img" src={userInfo.profile_picture_url} alt="profile-img" />) : 
                    
                    (<Skeleton animation="wave" variant="rounded" width={231} height={220} />)} 
                    
                  <div className="profile-filled-top-2-section-header">
                    <div className="profile-filled-section-title-1 profile-filled-opensans-semi-bold-regent-gray-10px">
                      Top 2
                    </div>
                    <img className="profile-filled-section-bleed-1" src={Bleed} alt="section bleed 1" />
                  </div>
                  <MostRecentWorkExperience

                    companyName="The Peter Obi Movement"
                    companyAddress={
                      <React.Fragment>
                        Nigerian Politics
                        <br />
                        Nigerian's Answer to a Year Long Cry
                      </React.Fragment>
                    }
                  />
                  <MostRecentWorkExperience

                    companyName="The Peter Obi Movement"
                    companyAddress={
                      <React.Fragment>
                        Nigerian Politics
                        <br />
                        Nigerias Answer to a Year Long Cry
                      </React.Fragment>
                    }
                    className="profile-filled-top-2-title-div-2"
                  />
                  <div className="profile-filled-genre-section-heading">
                    <div className="profile-filled-section-title-2 profile-filled-opensans-semi-bold-regent-gray-10px">
                      Genere
                    </div>
                    <img className="profile-filled-section-bleed-2" src={Bleed2} alt="section bleed 2" />
                  </div>
                  <div className="profile-filled-name">
                    Christian Leasdership
                  </div>
                  <div className="profile-filled-flex-col-item">
                    Nigerian Politics
                  </div>
                  <div className="profile-filled-flex-col-item">
                    Sports
                  </div>
                </div>
                <div className="profile-filled-flex-col-2 profile-filled-opensans-semi-bold-regent-gray-10px">
                  <div className="profile-filled-flex-row">
                    <div className="profile-filled-flex-col-3">
                      <h1 className="profile-filled-account-name profile-filled-opensans-bold-black-24px">
                        {(userInfo.first_name && userInfo.last_name) ? userInfo.first_name + " " + userInfo.last_name :(<Skeleton variant="text" width={160} height={28} sx={{ fontSize: '1rem' }} />) }
                      </h1>
                      <div className="profile-filled-profession profile-filled-opensans-bold-ocean-blue-pearl-14px">
                        {userInfo.username ? `@${userInfo.username}` : (<Skeleton variant="text" width={82} height={17} sx={{ fontSize: '1rem' }} />)} 
                      </div>
                      <div className="profile-filled-flex-row-1">
                        <div className="profile-filled-ranking">
                          <div className="profile-filled-rankings profile-filled-opensans-semi-bold-regent-gray-14px">
                            Rankings
                          </div>
                          <div className="profile-filled-rating-scoreheronew-semi-bold-pale-sky-24px">
                            8.8
                          </div>
                        </div>
                        <Rating
 />
                      </div>
                      <div className="profile-filled-about">
                        <img
                          className="profile-filled-iconboldprofile-circle"
                          src={ProfileCircle}
                          alt="icon/bold/profile-circle"
                        />
                        <div className="profile-filled-about-1 profile-filled-opensans-semi-bold-black-14px">
                          About
                        </div>
                      </div>
                    </div>
                    <div className="profile-filled-location">
                      <img className="profile-filled-iconboldlocation" src={Location} alt="icon/bold/location" />
                      <div className="profile-filled-location-1 profile-filled-opensans-semi-bold-regent-gray-14px">
                        Rivers, PH
                      </div>
                    </div>
                    <Button22
 />
                  </div>
                  <div className="profile-filled-overlap-group2">
                    <img className="profile-filled-line-1" src={Line} alt="Line 1" />
                    <img className="profile-filled-rectangle-3135" src={Rectangle2} alt="Rectangle 3135" />
                  </div>
                  <div className="profile-filled-contact-information">
                    CONTACT INFORMATION
                  </div>
                  <div className="profile-filled-flex-row-2">
                    <div className="profile-filled-phone profile-filled-opensans-semi-bold-pale-sky-14px">
                      Phone:
                    </div>
                    <div className="profile-filled-text-1 profile-filled-opensans-semi-bold-ocean-blue-pearl-14px">
                      {userInfo.phone_number ? (userInfo.phone_number) : (<Skeleton variant="rounded" width={124}  height={16} />)}
                    </div>
                  </div>
                  <div className="profile-filled-address-container profile-filled-opensans-semi-bold-pale-sky-14px">
                    <div className="profile-filled-address">
                      Address:
                    </div>
                    <p className="profile-filled-address-1">
                      525 F 68th Street New York,
                    </p>
                  </div>
                  <div className="profile-filled-flex-row-3">
                    <div className="profile-filled-e-mail profile-filled-opensans-semi-bold-pale-sky-14px">
                      E-mail:
                    </div>
                    <div className="profile-filled-hellolemonfalanacom profile-filled-opensans-semi-bold-ocean-blue-pearl-14px">
                      {userInfo.email ? (userInfo.email) : (<Skeleton variant="text" width={210} height={16} sx={{ fontSize: '1rem' }} />)}
                    </div>
                  </div>
                  <div className="profile-filled-flex-row-4">
                    <div className="profile-filled-site profile-filled-opensans-semi-bold-pale-sky-14px">
                      Site:
                    </div>
                    <div className="profile-filled-wwwnseabasi001com profile-filled-opensans-semi-bold-ocean-blue-pearl-14px">
                      www.nseabasi001.com
                    </div>
                  </div>
                  <div className="profile-filled-basic-information">
                    BASIC INFORMATION
                  </div>
                  <div className="profile-filled-flex-row-5 profile-filled-opensans-semi-bold-pale-sky-14px">
                    <div className="profile-filled-birthday">
                      Birthday:
                    </div>
                    <div className="profile-filled-date">
                    {userInfo.dob ? (userInfo.dob) : (<Skeleton variant="text" sx={{ fontSize: '1rem' }} />)}
                    </div>
                  </div>
                  <div className="profile-filled-flex-row-6 profile-filled-opensans-semi-bold-pale-sky-14px">
                    <div className="profile-filled-gender">
                      Gender:      
                    </div>
                    <div className="profile-filled-female">
                    {userInfo.gender ? (userInfo.gender) : (<Skeleton variant="text" sx={{ fontSize: '1rem' }} />)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MuiMaterial.ThemeProvider>
    </Antd.ConfigProvider>
  );
}

export default App;
const theme = MuiMaterial.createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#9c27b0",
    },
    error: {
      main: "#d32f2f",
    },
    warning: {
      main: "#ed6c02",
    },
    info: {
      main: "#0288d1",
    },
    success: {
      main: "#2e7d32",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontSize: 14,
  },
  shape: {
    borderRadius: 4,
  },
});
Antd.ConfigProvider.config({
  theme: {
    primaryColor: "#1890ff",
    secondaryColor: "#1890ff",
    successColor: "#52c41a",
    warningColor: "#faad14",
    errorColor: "#f5222d",
  },
});

function Sidebar22(props) {
  const { celecast, rectangle99, creator, frame162Props, frame162Props2, frame164Props, frame164Props2, frame165Props } = props;

  return (
    <div className="profile-filled-sidebar">
      <div className="profile-filled-logo-container">
        <Frame2/>
        <div className="profile-filled-logo-frame">
          <div className="profile-filled-overlap-group">
            <div className="profile-filled-celecast timesnewroman-bold-white-18px">
              {celecast}
            </div>
            <img className="profile-filled-rectangle-99" src={rectangle99} alt="Rectangle 99" />
          </div>
          <div className="profile-filled-creator poppins-medium-amber-18px">
            {creator}
          </div>
        </div>
      </div>
      <div className="profile-filled-overview profile-filled-opensans-normal-cornflower-14px">
        OVERVIEW
      </div>
      <Link to="/dashboard">
      <Frame162
        icOutlineSpaceDashboard={frame162Props.icOutlineSpaceDashboard}
        dashBoard={frame162Props.dashBoard}
      />
      </Link>
      <Link to="/dashboard/mypodcasts">
      <Frame162
        icOutlineSpaceDashboard={frame162Props2.icOutlineSpaceDashboard}
        dashBoard={frame162Props2.dashBoard}
        className={frame162Props2.className}
      />
      </Link>
      <div className="profile-filled-tools profile-filled-opensans-normal-cornflower-14px">
        TOOLS
      </div>
      <Link to="/dashboard/profile">
      <Frame162

        icOutlineSpaceDashboard={frame164Props.icOutlineSpaceDashboard}
        dashBoard={frame164Props.dashBoard}
        className={frame164Props.className}
      />
      </Link>
      <Link to="#">
      <Frame162

        icOutlineSpaceDashboard={frame164Props2.icOutlineSpaceDashboard}
        dashBoard={frame164Props2.dashBoard}
        className={frame164Props2.className}
      />
      </Link>
      <Link to="#">
      <Frame162
        icOutlineSpaceDashboard={frame165Props.icOutlineSpaceDashboard}
        dashBoard={frame165Props.dashBoard}
        className={frame165Props.className}
      />
      </Link>
      <div className="profile-filled-version-100 profile-filled-valign-text-middle profile-filled-opensans-normal-white-16px">
        Version 1.0.0
      </div>
    </div>
  );
}

function Frame2() {
  return <div className="profile-filled-logo-background"></div>;
}



function Frame8143(props) {
  const { topNavBarContentProps } = props;

  return (
    <div className="profile-filled-header-tab">
      <TopNavBarContent
 sectionLabelProps={topNavBarContentProps.sectionLabelProps} />
      <Frame8142/>
    </div>
  );
}

function TopNavBarContent(props) {
  const { sectionLabelProps } = props;

  return (
    <div className="profile-filled-top-nav-bar-content">
      <div className="profile-filled-frame-8106">
        <SectionLabel>{sectionLabelProps.children}</SectionLabel>
      </div>

    </div>
  );
}

function SectionLabel(props) {
  const { children } = props;

  return (
    <div className="profile-filled-section-label">
      <div className="profile-filled-label profile-filled-opensans-semi-bold-scarpa-flow-14px">
        {children}
      </div>
    </div>
  );
}

function Avatar() {
  const [userInfo, setUserInfo] = useState({profile_picture_url: null})

  useEffect(() => {
    const fetchUserDetails = async() => {
      try {
        const response = await 
          fetch('https://api.celecast.co/users/get_user_profile/', {
            method: 'GET',
            headers: {
              'Authorization': `Token ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        setUserInfo({
          profile_picture_url: data.profile_picture_url, 
         });
      } catch (error) {
        console.log("Error:" , error);
        console.log(localStorage.getItem('token'))
      }
     };
     fetchUserDetails();
  }, []);

  return (
    <div className="profile-filled-avatar">
      <img className="profile-filled-avatar" src={userInfo.profile_picture_url} alt="Profile" />
    </div>
  );
}

function Frame8142() {
  return (
    <div className="profile-filled-frame-8142">
      <Fab/>
      <Avatar
 />
    </div>
  );
}

function Fab() {
  return (
    <div className="profile-filled-fab">
      <MuiMaterial.Fab size="medium" color="inherit" disabled={false} variant="circular" disableRipple={false}>
        <MuiIconsMaterial.Notifications />
      </MuiMaterial.Fab>
    </div>
  );
}



function MostRecentWorkExperience(props) {
  const { companyName, companyAddress, className } = props;

  return (
    <div className={`top-2-title-div-1 ${className || ""}`}>
      <div className="profile-filled-company-name profile-filled-opensans-bold-pale-sky-14px">
        {companyName}
      </div>
      <p className="profile-filled-company-address profile-filled-opensans-semi-bold-regent-gray-14px">
        {companyAddress}
      </p>
    </div>
  );
}

function Rating() {
  return (
    <div className="profile-filled-rating">
      <MuiMaterial.Rating max={4} size="medium" precision={0.5} defaultValue={2} disabled={false} />
    </div>
  );
}

function Button22() {
  return (
    <div className="profile-filled-edit-profile-button">
      <Link to="/edit-profile">
      <MuiMaterial.Button
        endIcon=""
        size="large"
        color="primary"
        disabled={false}
        variant="contained"
        disableElevation={false}
        startIcon={<MuiIconsMaterial.Edit />}
      >
        EDIT
      </MuiMaterial.Button>
      </Link>
    </div>
  );
}

const frame1621Data = {
  icOutlineSpaceDashboard: {DashboardOutline},
  dashBoard: "Dash Board",
};

const frame1622Data = {
  icOutlineSpaceDashboard: {SoundIcon2},
  dashBoard: "My Podcasts",
  className: "profile-filled-s-frame-1",
};

const frame1623Data = {
  icOutlineSpaceDashboard: {Profile},
  dashBoard: "Profile",
  className: "profile-filled-profile-frame",
};

const frame1624Data = {
  icOutlineSpaceDashboard: {Carbon},
  dashBoard: "Settings",
  className: "profile-filled-s-frame",
};

const frame1625Data = {
  icOutlineSpaceDashboard: {LogoutIcon},
  dashBoard: "Logout",
  className: "profile-filled-logout-frame",
};

const sidebar22Data = {
  celecast: "Celecast",
  rectangle99: {Rectangle},
  creator: "Creator",
  frame162Props: frame1621Data,
  frame162Props2: frame1622Data,
  frame164Props: frame1623Data,
  frame164Props2: frame1624Data,
  frame165Props: frame1625Data,
};

const sectionLabelData = {
  children: "Profile",
};

const topNavBarContentData = {
  sectionLabelProps: sectionLabelData,
};

const frame8143Data = {
  topNavBarContentProps: topNavBarContentData,
};
 function Frame162(props) {
   const { icOutlineSpaceDashboard, dashBoard, className, onClick } = props;

   return (
    <div onClick={onClick} className={`frame-8133 ${className || ""}`}>
      <img className="iconoutlinelogout" src={icOutlineSpaceDashboard} alt="icon/outline/logout" />
      <div className="logout opensans-normal-solitude-14px">
        {dashBoard}
      </div>
    </div>
  );
 }

function Frame1(props) {
  const { dashBoard, className } = props;

  return (
    <div className={`frame-1 ${className || ""}`}>
      <img className="no-outline" src={DashboardOutline} alt="ic:outline-space-dashboard" />
      <div className="dash-board opensans-normal-solitude-14px">
        {dashBoard}
      </div>
    </div>
  );
}

function Frame8136(props) {
  const { profile } = props;

  return (
    <div className="frame-8131">
      <img className="iconoutlinesound" src={SoundIcon} alt="icon/outline/sound" />
      <div className="my-podcasts opensans-normal-solitude-14px">
        {profile}
      </div>
    </div>
  );
}