import React, { useState } from "react";
import { styled } from "styled-components";
import AuthLayout from "../layout";
import LoginFooter from "./footer";
import { Form, Formik } from "formik";
import { LoginValidation } from "../formValidation";
import { TextField } from "../../../components/formikTextInput";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { login } from "../../../api/auth";
import { CircularProgress } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const { setAuth } = useAuth();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const res = await login(formData);
      navigate(from, { replace: true });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout footerComponent={LoginFooter}>
      <Container>
        <TitleWrapper>
          <Title>Welcome Back</Title>
          <SubTitle>
            To gain access to your account, enter your login information.
          </SubTitle>
        </TitleWrapper>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={LoginValidation}
          enableReinitialize
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          <Form>
            <FormWrapper>
              <TextField
                name="email"
                placeholder="Enter your e-mail"
                type="email"
              />
              <TextField
                name="password"
                placeholder="Enter your password"
                type="password"
              />
              <FormButton type={"submit"} disabled={loading}>
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  `Sign In`
                )}
              </FormButton>
            </FormWrapper>
          </Form>
        </Formik>
      </Container>
    </AuthLayout>
  );
};

export default Login;

export const Container = styled.div`
@font-face {
  font-family: 'Open Sans';
  src: url('/public/fonts/OpenSans-Regular.ttf') format('truetype');
}
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const Title = styled.p`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const SubTitle = styled.p`
  color: #fff;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 322px;
  margin-top: 56px;
`;

export const FormInput = styled.input`
  width: 322px;
  height: 43px;
  flex-shrink: 0;
  background: transparent;
  padding-left: 16px;
  border-radius: 6px;
  outline: none;
  border: 1px solid #adbbdb;

  &::placeholder {
    color: #adbbdb;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const FormButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 43px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid rgba(33, 41, 52, 0.07);
  background: #177ae5;
  box-shadow: 0px 1px 0px 0px rgba(27, 31, 35, 0.05);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 10px;
  opacity: 0.85;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;
