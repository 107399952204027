import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import Carousel from '../commons/carousel/Carousel';

import styles from './Home.module.css';
import MobileNav from '../commons/mobileNavigation/MobileNav';
import Img1 from "../../assets/landingPageAssets/Ellipse1.png";
import Img2 from "../../assets/landingPageAssets/yellow-blue-light.png";
import Logo from "../../assets/landingPageAssets/platforms.png";
import Logo2 from "../../assets/landingPageAssets/platforms-small.png";
import Growth from "../../assets/landingPageAssets/growth-img.svg";
import Analytics from "../../assets/landingPageAssets/analytics.svg";
import UserPic from "../../assets/landingPageAssets/userpic.png";

const Home = () => {
  return (
    <div className={styles['home-container']}>
      <div>
        <div className={styles.column}>
          <nav className={`${styles.row} ${styles.visible}`}>
            <div className={`${styles['nav-div']} ${styles.row} ${styles['space-between']}`}>
              <a href='/'>
                <p className={styles['logo-text']}>Celecast</p>
              </a>
              <div className={styles['toggle-button']}>
                <MobileNav />
              </div>
              <img className={styles.ellipse1} src={Img1}  alt='image 1'/>
              <img className={styles['joined-ellipse1']} src={Img2} alt='image 2'/>
              <img className={styles['joined-ellipse2']} src={Img2}  alt='image 2'/>
              <div className={styles['header-links-container']}>
                <ul className={`${styles.row} ${styles['space-between']} ${styles['header-links-ul']}`}>
                  <li>
                    <Link className={styles['react-link']} to="/">
                      Home
                    </Link>
                  </li>
                  <li>Integration</li>
                  <li>Demo</li>
                </ul>
              </div>
              <p className={styles['sign-in-link']}>
                <Link className={styles['react-link']} to="/login">
                  Sign In
                </Link>
              </p>
            </div>
          </nav>
          <div className={`${styles['first-page']} ${styles.column} ${styles['align-center']}`}>
            <p className={styles['main-header-text']}>
              Podcasting has never been simpler <br /> or more comfortable
            </p>
            <p className={styles['sub-header-text']}>
              We assist you in achieving your personal and corporate goals by providing a user-friendly <br />
              interface and a pay as you go plan that is designed to be very affordable
            </p>
            <div className={`${styles['button-set-1']} ${styles.row} ${styles['space-between']}`}>
              <button className={styles['filled-button']}>Port to Celecast</button>
              <button className={styles['outlined-button']}>Sign Up</button>
            </div>
            <div className={`${styles.column} ${styles['align-center']}`}>
              <p className={styles['platforms-title-header']}>MOVE YOUR CONTENTS FROM OTHER PLATFORMS WITH EASE</p>
              <img alt='logo' className={styles['platforms-logo-image']} src={Logo} />
              <img alt='logo' className={styles['platforms-logo-image-small']} src={Logo2} />
          </div>
        </div>
        </div>
        <div className={styles['second-page']}>
        <div className={`${styles.column} ${styles['space-between']} ${styles['align-center']}`}>
          <div className={`${styles.row} ${styles['space-between']} ${styles['feature-growth-container']}`}>
            <div className={styles['growth-text']}>
              <p className={styles['main-header-text-2']}>
                We are dedicated to <span className={styles['blue-in-text']}>helping</span> you grow
              </p>
              <p className={styles['sub-header-text-2']}>
                We designed our system so that you can make the most of our platform and your dashboard regardless of your level of experience.
              </p>
            </div>
            <img alt='growth-img' src={Growth} />
          </div>
          <div className={`${styles.row} ${styles['space-between']} ${styles['feature-analytics-container']}`}>
            <img alt='analytics-img' src={Analytics} />
            <div className={styles['analytics-text']}>
              <p className={styles['main-header-text-2']}>
                Make better informed decisions with <span className={styles['blue-in-text']}>accurate analytics</span>
              </p>
              <p className={styles['sub-header-text-2']}>
                We understand that accurate data insights are critical in assisting you in developing an accurate trajectory for your business, and we make that possible through real-time analytics.
              </p>
            </div>
          </div>
        </div>
        </div>
        <div className={styles['second-page-small']}>
        <div className={`${styles.column} ${styles['space-between']} ${styles['align-center']}`}>
          <div className={`${styles.column} ${styles['feature-growth-container']}`}>
            <div className={styles['growth-text']}>
              <p className={styles['main-header-text-2']}>
                We are dedicated to <span className={styles['blue-in-text']}>helping</span> you grow
              </p>
              <img alt='growth-img' src={Growth} />
              <p className={styles['sub-header-text-2']}>
                We designed our system so that you can make the most of our platform and your dashboard regardless of your level of experience.
              </p>
            </div>
          </div>
          <div className={`${styles.column} ${styles['space-between']} ${styles['feature-analytics-container']}`}>
            <div className={`${styles.column} ${styles['analytics-text']}`}>
              <p className={styles['main-header-text-2']}>
                Make better informed decisions with <span className={styles['blue-in-text']}>accurate analytics</span>
              </p>
              <img alt='analytics-img' src={Analytics} />
              <p className={styles['sub-header-text-2']}>
                We understand that accurate data insights are critical in assisting you in developing an accurate trajectory for your business, and we make that possible through real-time analytics.
              </p>
            </div>
          </div>
        </div>
        </div>
        <div className={`${styles['third-page']} ${styles.column} ${styles['align-center']}`}>
          <div className={`${styles.column} ${styles['align-center']} ${styles['customer-reviews']}`}>
        <p className={styles['main-header-text-2']}>
          What our <span className={styles['blue-in-text']}>customers</span> have to say about us.
        </p>
        <div className={styles['carousel-component']}>
          <Carousel />
        </div>
        <div className={`${styles['card-grid-container']} ${styles.row} ${styles['space-between']}`}>
          <div className={styles.column}>
            <div className={`${styles.card} ${styles['card-regular']}`}>
              <div className={styles.column}>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Ullamcorper ullamcorper eu ut in consequat amet sapien commodo potenti.
                  Viverra porta sit ultrices ante neque eget eu. Nibh urna tincidunt diam tempor vel ut urna. Volutpat.
                </p>
                <div className={`${styles.row} ${styles['align-center']} ${styles['user-details']}`}>
                  <img className={styles.userpic} alt='user' src={UserPic} />
                  <div className={styles.column}>
                    <p className={styles['no-margin']}>Esther James</p>
                    <p className={`${styles['no-margin']} ${styles.grey}`}>Product Designer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.card} ${styles['card-medium']}`}>
              <div className={styles.column}>
                <p>
                  Lorem ipsum dolor sit amet consectetur. In vulputate aliquam velit a pharetra vestibulum mi.
                  Rhoncus ornare pretium mauris vulputate porttitor sodales. Volutpat erat auctor odio id elit iaculis massa donec imperdiet.
                  Ornare adipiscing enim lacus sed massa dictum tortor.
                </p>
                <div className={`${styles.row} ${styles['align-center']} ${styles['user-details']}`}>
                  <img className={styles.userpic} alt='user' src={UserPic} />
                  <div className={styles.column}>
                    <p className={styles['no-margin']}>Esther James</p>
                    <p className={`${styles['no-margin']} ${styles.grey}`}>Product Designer</p>

                      </div>
                    </div>
                  </div>
                  
                </div>
            </div>

            <div className={styles.column}>
        <div className={`${styles.card} ${styles['card-long']}`}>
        <div className={styles.column}>
          <p>
            Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium. Amet risus eu varius rhoncus.
            Tincidunt nulla augue amet tortor massa amet. Amet massa consequat libero egestas pulvinar massa justo eget nulla.
            Ornare rhoncus molestie vel etiam imperdiet. Nibh auctor sagittis.
          </p>
          <div className={`${styles.row} ${styles['align-center']} ${styles['user-details']}`}>
            <img className={styles.userpic} alt='user' src={UserPic} />
            <div className={styles.column}>
              <p className={styles['no-margin']}>Esther James</p>
              <p className={`${styles['no-margin']} ${styles.grey}`}>Product Designer</p>
            </div>
          </div>
        </div>
        </div>

       <div className={`${styles.card} ${styles['card-small']}`}>
        <div className={styles.column}>
          <p>
            Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium. Amet risus eu varius rhoncus. Tincidunt nulla .
          </p>
          <div className={`${styles.row} ${styles['align-center']} ${styles['user-details']}`}>
            <img className={styles.userpic} alt='user' src={UserPic} />
            <div className={styles.column}>
              <p className={styles['no-margin']}>Esther James</p>
              <p className={`${styles['no-margin']} ${styles.grey}`}>Product Designer</p>
            </div>
          </div>
        </div>
        </div>
        </div>
        <div className={styles.column}>
        <div className={`${styles.card} ${styles['card-square']}`}>
        <div className={styles.column}>
          <p>
            Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium. Amet risus eu varius rhoncus.
            Tincidunt nulla augue amet tortor massa amet. Amet massa consequat libero egestas pulvinar massa justo eget
          </p>
          <div className={`${styles.row} ${styles['align-center']} ${styles['user-details']}`}>
            <img className={styles.userpic} alt='user' src={UserPic} />
            <div className={styles.column}>
              <p className={styles['no-margin']}>Esther James</p>
              <p className={`${styles['no-margin']} ${styles.grey}`}>Product Designer</p>
              </div>
                      </div>
                    </div>
                    
                  </div>
                  
                  <div className={`${styles.card} ${styles['card-square']}`}>
          <div className={styles.column}>
          <p>
          Lorem ipsum dolor sit amet consectetur. Maecenas at in ipsum rutrum felis pretium.
          Amet risus eu varius rhoncus. Tincidunt nulla augue amet tortor massa amet.
          Amet massa consequat libero egestas pulvinar massa justo eget
         </p>
          <div className={`${styles.row} ${styles['align-center']} ${styles['user-details']}`}>
          <img className={styles.userpic} alt='user' src={UserPic} />
          <div className={styles.column}>
            <p className={`${styles['no-margin']}`}>Esther James</p>
            <p className={`${styles['no-margin']} ${styles.grey}`}>Product Designer</p>
            </div>
                    </div>
                  </div>
                  
                </div>
                </div>
            </div>
          </div>
        </div>
        <div className={`${styles.column} ${styles['align-center']}`}>
        <div className={`${styles['signup-block']} ${styles.column} ${styles['align-center']}`}>
        <p className={styles['main-header-text-3']}> Sign up to get early access</p>
        <p className={`${styles['main-header-text-3']} ${styles['no-margin']}`}>
          Get your first <span className={styles['blue-in-text']}>subscription for half</span> off
        </p>
        <div className={`${styles['button-set-1']} ${styles.row} ${styles['space-between']}`}>
          <button className={styles['filled-button']}>Port to celecast</button>
          <button className={styles['outlined-button']}>Sign Up</button>
            </div>
        </div>
        </div>
      <div className={styles['fourth-page']}>
      <div className={`${styles.column} ${styles['footer']}`}>
        <p className={styles['logo-text']}>Celecast</p>
        <div className={`${styles.row} ${styles['space-between']} ${styles['footer-links-socials-subscribe-container']}`}>
          <div className={`${styles.column} ${styles['subscribe-column-small']} ${styles['align-center']}`}>
            <p className={`${styles['subscribe-text-header']} ${styles['no-margin']}`}>Subscribe to our newsletter</p>
            <p className={styles['subscribe-text-sub-header']}>Be in the loop with happenings at Celecast</p>
            <input className={styles['subscribe-textfield']} type="email" />
            <button className={styles['filled-button-long']}>Subscribe</button>
          </div>
          <div className={styles.column}>
            <ul className={`${styles.column} ${styles['footer-links']}`}>
              <li>Home</li>
              <li>Contact Us</li>
              <li>About Us</li>
              <li>Pricing</li>
            </ul>
          </div>
          <div className={styles.column}>
            <p className={styles['socials-link-heading']}>Socials</p>
            <ul className={`${styles.column} ${styles['align-center']} ${styles['footer-links']}`}>
              <li>Facebook</li>
              <li>Twitter</li>
              <li>LinkedIn</li>
            </ul>
          </div>
          <div className={`${styles.column} ${styles['subscribe-column']}`}>
            <p className={`${styles['subscribe-text-header']} ${styles['no-margin']}`}>Subscribe to our newsletter</p>
            <p className={styles['subscribe-text-sub-header']}>Be in the loop with happenings at Celecast</p>
            <input className={styles['subscribe-textfield']} type="email" />
            <button className={styles['filled-button-long']}>Subscribe</button>
          </div>
        </div>
        <div className={`${styles.column} ${styles['align-center']} ${styles['hr-div']}`}>
          <hr className={styles.hr} />
        </div>
        <div className={`${styles.column} ${styles['align-center']}`}>
          <p className={styles['copyright']}>Copyright Celecast 2023</p>
        </div>
      </div>
    </div>
        <div></div>
        </div>
        </div>

    

  )
}

export default Home