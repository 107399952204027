import React, { useEffect } from "react";
import DashboardMain from "./DashboardMain";
import DashboardDiscovery from "./DashboardDiscovery";
import DashboardPodcasts from "./DashboardPodcasts";
import EditProfile from "./EditProfile";
import MyPodcastsUploadOption from "./MyPodcastsUploadOption";
import ProfileFilled from "./ProfileFilled";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./dashboardStylesheets/DashboardViewApp.css";
import DashboardOutline from "../../assets/dashboardAssets/ic-outline-space-dashboard.svg";
import SoundIcon from "../../assets/dashboardAssets/icon-outline-sound.svg";

const DashboardViewApp = (props) => {
  useEffect(() => {
    <Navigate replace to="/dashboard" />;
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard" element={<DashboardMain />}></Route>
        <Route path="/discovery" element={<DashboardDiscovery />}></Route>
        <Route path="/profile" element={<ProfileFilled />}></Route>
        <Route path="/settings" element={<DashboardMain />}></Route>
        <Route path="/mypodcasts" element={<DashboardPodcasts />}></Route>
        <Route path="/edit-profile" element={<EditProfile />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default DashboardViewApp;

function Frame162(props) {
  const { icOutlineSpaceDashboard, dashBoard, className } = props;

  return (
    <div className={`frame-8133 ${className || ""}`}>
      <img
        className="iconoutlinelogout"
        src={icOutlineSpaceDashboard}
        alt="icon/outline/logout"
      />
      <div className="logoutopensans-normal-solitude-14px">{dashBoard}</div>
    </div>
  );
}

function Frame1(props) {
  const { dashBoard, className } = props;

  return (
    <div className={`frame-1 ${className || ""}`}>
      <img
        className="no-outline"
        src={DashboardOutline}
        alt="ic:outline-space-dashboard"
      />
      <div className="dash-boardopensans-normal-solitude-14px">{dashBoard}</div>
    </div>
  );
}

function Frame8136(props) {
  const { profile } = props;

  return (
    <div className="frame-8131">
      <img
        className="iconoutlinesound"
        src={SoundIcon}
        alt="icon/outline/sound"
      />
      <div className="my-podcastsopensans-normal-solitude-14px">{profile}</div>
    </div>
  );
}
