import React, { useState } from "react";
import { Form, Formik } from "formik";
import AuthLayout from "../layout";
import {
  Container,
  FormButton,
  FormWrapper,
  SubTitle,
  Title,
  TitleWrapper,
} from "../login";
import RegisterFooter from "./footer";
import { register } from "../../../api/auth";
import { SignUpValidation } from "../formValidation";
import { TextField } from "../../../components/formikTextInput";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const res = await register(formData);
      navigate("/login", { replace: true });
    } catch (error) {
      console.log({ error });
      // parseError(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <AuthLayout footerComponent={RegisterFooter}>
      <Container>
        <TitleWrapper>
          <Title>Hi there</Title>
          <SubTitle>Register for free to gain access to our services.</SubTitle>
        </TitleWrapper>
        <Formik
          initialValues={{
            full_name: "",
            username: "",
            email: "",
            password: "",
          }}
          validationSchema={SignUpValidation}
          enableReinitialize
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          <Form>
            <FormWrapper>
              <TextField
                name="full_name"
                placeholder="Enter your full name"
                type="text"
              />
              <TextField
                name="email"
                placeholder="Enter your e-mail"
                type="email"
              />
              <TextField
                name="username"
                placeholder="Set your user name"
                type="text"
              />
              <TextField
                name="password"
                placeholder="Set your password"
                type="password"
              />
              <FormButton type={"submit"} disabled={loading}>
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  `Register`
                )}
              </FormButton>
            </FormWrapper>
          </Form>
        </Formik>
      </Container>
    </AuthLayout>
  );
};

export default Register;
