import React from 'react'
import mobileNav from "./MobileNav.module.css"
import { Link } from "react-router-dom";
import toggleMenu from "../../../assets/landingPageAssets/menu.svg";

const MobileNav = (isOpen, setIsOpen) => {
    const openNav = () => {
        document.getElementById("mySidenav").style.width= "250px"
    }
    
    const closeNav = () => {
        document.getElementById("mySidenav").style.width= "0"
    }
  return (
    <div>
        <img alt='toggle-button' onClick={openNav} src={toggleMenu} />
        <div id="mySidenav" className={mobileNav.sidenav}>
            <a href="javascript:void(0)" className={mobileNav.closebtn} onClick={closeNav}>&times;</a>
            <Link to="/" >Home</Link>
            <Link to="" >Integration</Link>
            <Link to="" >Demo</Link>
            <Link to="/login" >Sign In</Link>
            <Link to="/signup" >Sign Up</Link>
            <Link to="/forgotpassword" >Forgot Password</Link>
            <Link to="/resetpassword" >Reset Password</Link>
        </div>
    </div>
  )
}

export default MobileNav