import React from 'react'
import './PageNotFound.css'
import { useNavigate } from 'react-router-dom'
import SadFace from "../../assets/images/sad-pic.svg";


const Error = () => {
  let navigate = useNavigate(); 

  function refreshPage() {
    window.location.reload(false);
  }
  return (
    <div className='margin-top-60px'>
   
        <div className="text-box">
        <h3 className="heading margin-bottom-48px">Oops</h3>
        <p className="sub-heading">Seems you're lost</p>
        <p className="sub-heading">Not to worry it's just a 404 error</p>
    </div>
    <div className="button-span">
        <button className="blue-button" onClick={() => navigate(-1)}>Back</button>
        <button className="white-button" onClick={refreshPage}>Reload</button>
    </div>
    <div className="sad-pic" >
        <img src={SadFace} alt="Sad-face"/>
    </div>
    </div>
  )
}

export default Error